<template>
  <app-cell
    class="blog-cell"
    :edit="!!form"
    :deletable="!!blog"
    :loading="!!form && form.isLoading"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="blog">
      <div class="blog-cell__content">
        <h2 class="blog-cell__title">{{ blog.title }}</h2>
        <div class="blog-cell__video" v-if="blog.video">
          <h3>
            Video
            <strong v-if="blog.video.isCitizenRestricted">(citizens only!)</strong>
          </h3>
          <p class="blog-cell__entry">
            <span class="entry__label">Amazon storage key:</span>
            <a :href="blog.video.url" target="_blank">{{ blog.video.fileKey }}</a>
            <span>{{ ' (' }}</span>
            <a @click="$emit('watch', blog.video.asset.streamUrl)">MUX</a>
            <span>)</span>
          </p>
          <p class="blog-cell__entry">
            <span class="entry__label">Length:</span>
            <span v-if="blog.video.asset.durationInSeconds">{{ blog.video.asset.durationInSeconds | duration }}</span>
            <span v-else>Unknown</span>
          </p>
        </div>
        <div class="blog-cell__live-stream" v-if="blog.liveStream">
          <h3>
            Live stream
            <strong v-if="blog.liveStream.isCitizenRestricted">(citizens only!)</strong>
          </h3>
          <p class="blog-cell__entry">
            <span class="entry__label">Status:</span>
            <a v-if="liveStreamDidStart || liveStreamIsActive" @click="$emit('watch', blog.liveStream.streamUrl)">
              <span>{{ blog.liveStream.status }}</span>
            </a>
            <span v-else>Scheduled at {{ blog.liveStream.scheduledAt | dayjs('MMMM DD YYYY HH:mm') }}</span>
          </p>
          <p class="blog-cell__entry">
            <span class="entry__label">Stream server:</span>
            <span>rtmps://global-live.mux.com:443/app</span>
          </p>
          <p class="blog-cell__entry">
            <span class="entry__label">Stream key:</span>
            <span>{{ blog.liveStream.streamKey }}</span>
          </p>
          <ul class="blog-cell__simulcasts">
            <li class="simulcasts__simulcast" v-for="simulcast in blog.liveStream.simulcasts" :key="simulcast.id">
              <p class="simulcast__name">{{ simulcast.name }}</p>
              <p class="simulcast__status">
                <strong>
                  <span v-if="simulcast.webhookStatus === MuxWebhookStatus.ToBeDeleted">TO BE DELETED</span>
                  <span v-else>{{ simulcast.status }}</span>
                </strong>
              </p>
            </li>
          </ul>
          <ul class="blog-cell__assets">
            <li v-for="asset in blog.liveStream.assets" :key="asset.id">
              <a @click="$emit('watch', asset.streamUrl)">
                <span>{{ asset.createdAt | dayjs('MMMM DD YYYY HH:mm') }}</span>
              </a>
              <span>{{ asset.durationInSeconds | duration }}</span>
            </li>
          </ul>
        </div>
        <p class="blog-cell__body">
          <img class="blog-cell__image" v-if="blog.imageUrl" :src="blog.imageUrl" :alt="blog.title" />
          <span v-html="blog.body" />
        </p>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="blog-cell__form">
        <ul class="form__errors" v-if="form.errorMessages">
          <li v-for="error in form.errorMessages" class="error" :key="error">
            {{ error }}
          </li>
        </ul>
        <base-form-input label="Blog title" class="form__input">
          <input v-model="form.title" placeholder="Blog title" />
        </base-form-input>
        <base-form-input label="Sort order" v-if="form.sortOrder != null" class="form__input">
          <input v-model.number="form.sortOrder" placeholder="Sort order" type="number" />
        </base-form-input>
        <base-form-input label="Cover photo" class="form__input">
          <input @change="onFileUpload" placeholder="Cover photo" type="file" accept="image/png, image/jpeg" />
        </base-form-input>
        <app-html-editor class="form__input" v-model="form.body" />
        <base-checkbox :value="showVideoForm" @update="onToggleVideoForm" class="form__input">Has video</base-checkbox>
        <div v-if="showVideoForm" class="form__video">
          <p class="form__description">
            Upload the video to Amazon S3 in root bucket
            <i>('avatar-country-dev' for test environment)</i>. Use the file name below, and save form at which point
            MUX starts doing it's compression.
          </p>
          <base-form-input label="Name on Amazon S3" class="form__input">
            <input v-model="form.videoForm.fileKey" placeholder="Name on Amazon S3" />
          </base-form-input>
          <base-checkbox v-model="form.videoForm.isCitizenRestricted" class="form__input">Citizens only</base-checkbox>
        </div>
        <base-checkbox :value="showLiveStreamForm" @update="onToggleLiveStreamForm" class="form__input"
          >Has live stream</base-checkbox
        >
        <div v-if="showLiveStreamForm" class="form__video">
          <base-form-input class="form__input" label="Scheduled at (UTC)" :optional="true">
            <base-date-input v-model="form.liveStreamForm.scheduledAt" type="datetime-local" :min="new Date()" />
          </base-form-input>
          <base-checkbox v-model="form.liveStreamForm.isCitizenRestricted" class="form__input"
            >Citizens only</base-checkbox
          >
          <ul class="form__simulcasts">
            <app-cell
              class="simulcasts__simulcast"
              v-for="simulcast in blog && blog.liveStream && blog.liveStream.simulcasts"
              :key="simulcast.id"
            >
              <p class="simulcast__name">
                <span>{{ simulcast.name }}</span>
                <strong>
                  <span v-if="simulcast.webhookStatus === MuxWebhookStatus.ToBeDeleted">TO BE DELETED</span>
                </strong>
              </p>
              <p>{{ simulcast.url }}</p>
              <p>{{ simulcast.streamKey }}</p>
              <template slot="actions">
                <base-button @click="onDeleteSimulcast(simulcast)" :danger="true">Delete</base-button>
              </template>
            </app-cell>
            <app-cell
              class="simulcasts__simulcast-form"
              v-for="simulcastForm in form.liveStreamForm.simulcastForms"
              :key="simulcastForm.id"
            >
              <base-form-input class="form__input" label="Name">
                <input v-model="simulcastForm.name" placeholder="Facebook" />
              </base-form-input>
              <base-form-input class="form__input" label="Url">
                <input v-model="simulcastForm.url" placeholder="rtmps://..." />
              </base-form-input>
              <base-form-input class="form__input" label="Stream key">
                <input v-model="simulcastForm.streamKey" placeholder="abc123" />
              </base-form-input>
              <template slot="actions">
                <base-button @click="onDeleteSimulcastForm(simulcastForm)" :danger="true">Delete</base-button>
              </template>
            </app-cell>
            <li class="simulcasts__add" key="add-simulcast">
              <base-button @click="onAddSimulcast">Add simulcast</base-button>
            </li>
          </ul>
        </div>
        <base-checkbox :value="showChatForm" @update="onToggleChatForm" class="form__input">Has chat</base-checkbox>
        <div v-if="showChatForm" class="form__video">
          <base-checkbox v-model="form.chatForm.disabled" class="form__input">Disable new comments</base-checkbox>
        </div>
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import { BlogForm, CreateBlogForm, UpdateBlogForm } from '@/forms/blog';
import { VideoForm } from '@/forms/video/VideoForm';
import { getFilesFromUploadEvent } from '@/utils/getFilesFromUploadEvent';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChatForm } from '../forms/chat/ChatForm';
import { LiveStreamForm } from '../forms/liveStream/LiveStreamForm';
import { SimulcastForm } from '../forms/liveStream/SimulcastForm';
import { Blog, LiveStreamStatus, MuxSimulcast, MuxWebhookStatus } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor },
})
export default class BlogCell extends Vue {
  @Prop()
  blog?: Blog;

  public MuxWebhookStatus = MuxWebhookStatus;

  public form = null as BlogForm | null;
  public showVideoForm = false;
  public showLiveStreamForm = false;
  public showChatForm = false;

  public get liveStreamDidStart(): boolean {
    return (this.blog && this.blog.liveStream && dayjs().isAfter(this.blog.liveStream.scheduledAt)) || false;
  }

  public get liveStreamIsActive(): boolean {
    return (this.blog && this.blog.liveStream && this.blog.liveStream.status === LiveStreamStatus.Active) || false;
  }

  public onEdit() {
    this.form = this.blog ? new UpdateBlogForm(this.blog) : new CreateBlogForm();
    this.showVideoForm = !!(this.blog && this.blog.video);
    this.showLiveStreamForm = !!(this.blog && this.blog.liveStream);
    this.showChatForm = !!(this.blog && this.blog.chat);
  }

  public onCancel() {
    this.closeForm();
  }

  public onFileUpload(e: HTMLFileUploadEvent) {
    const files = getFilesFromUploadEvent(e);
    this.form!.image = files[0];
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public onToggleVideoForm(show: boolean) {
    if (show) {
      this.form!.videoForm = new VideoForm((this.blog && this.blog.video) || undefined);
      this.showVideoForm = true;
    } else {
      this.form!.videoForm = null;
      this.showVideoForm = false;
    }
  }

  public onToggleLiveStreamForm(show: boolean) {
    if (show) {
      this.form!.liveStreamForm = new LiveStreamForm(this.blog?.liveStream || undefined);
      this.showLiveStreamForm = true;
    } else {
      this.form!.liveStreamForm = null;
      this.showLiveStreamForm = false;
    }
  }

  public onToggleChatForm(show: boolean) {
    if (show) {
      this.form!.chatForm = new ChatForm(this.blog?.chat || undefined);
      this.showChatForm = true;
    } else {
      this.form!.chatForm = null;
      this.showChatForm = false;
    }
  }

  public async onDelete() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteBlog($input: DeleteBlogInput!) {
          deleteBlog(input: $input) {
            success
          }
        }
      `,
      variables: { input: { id: this.blog!.id } },
      refetchQueries: ['Blogs'],
    });
  }

  public async onDeleteSimulcast(simulcast: MuxSimulcast) {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteSimulcast($input: DeleteSimulcastInput!) {
          deleteSimulcast(input: $input) {
            simulcast {
              id
              webhookStatus
            }
          }
        }
      `,
      variables: { input: { id: simulcast!.id } },
    });
  }

  public async onDeleteSimulcastForm(simulcastForm: SimulcastForm) {
    this.form!.liveStreamForm!.simulcastForms = this.form!.liveStreamForm!.simulcastForms.filter(
      (a) => a.id !== simulcastForm.id
    );
  }

  public async onAddSimulcast() {
    this.form!.liveStreamForm!.simulcastForms.push(new SimulcastForm());
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.blog) {
      this.form = new CreateBlogForm();
    }
  }
}
</script>

<style lang="scss" scoped>
.blog-cell {
  &__title {
    strong {
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }
  &__video,
  &__live-stream {
    width: 70%;
    padding: 12px 16px;
    margin-bottom: 16px;
    border: 1px solid $color-theme-red;
  }
  &__entry {
    .entry__label {
      font-weight: bold;
      margin-right: 8px;
    }
  }
  &__simulcasts {
    margin-top: 16px;
    li {
      padding: 12px 16px;
      margin-bottom: 16px;
      border: 1px solid $color-theme-red;
      display: flex;
      justify-content: space-between;
    }
  }
  &__assets {
    li {
      span {
        margin-right: 8px;
      }
    }
  }
  &__body {
    :deep(*) {
      margin: 0;
      line-height: 1.4em;
      padding: 8px 0;
    }
  }
  &__image {
    height: 220px;
    max-width: 400px;
    margin-right: 16px;
    float: left;
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .form__simulcasts {
      li {
        margin-bottom: 8px;
        .simulcast__name {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .form__add-video {
      margin-bottom: 8px;
    }
    .form__video {
      margin-bottom: 8px;
      padding: 12px 16px;
      border: 1px solid $color-theme-red;
    }
    .form__description {
      margin-bottom: 12px;
      i {
        font-style: italic;
      }
    }
    .form__errors {
      font-weight: bold;
      color: $color-theme-red;
      margin-bottom: 8px;
      line-height: 2em;
    }
  }
}
</style>
