<template>
  <app-cell
    class="show-cell"
    :edit="!!form"
    :deletable="!!show"
    :loading="!!form && form.isLoading"
    :focused="focused"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="show">
      <div class="show-cell__content">
        <h2 class="show-cell__title">
          <span>{{ show.address.venue }}</span>
          <span>{{ ` (${show.address.city}, ${show.address.country.id})` }}</span>
          <strong v-if="!showIsVisible">(visible from {{ show.visibleFrom | dayjs('MMMM DD, YYYY') }})</strong>
          <span v-if="show.tour" class="show-cell__tour">Tour: {{ show.tour.name }}</span>
        </h2>
        <p class="show-cell__body">
          <strong>{{ show.showDate | dayjs.utc('MMM DD, YYYY (HH:mm)') }}</strong>
        </p>
        <p class="show-cell__body">
          <span v-html="show.description"></span>
        </p>
        <div v-if="show.ticketSales" class="show-cell__box">
          <details>
            <summary class="entry">
              <span class="entry__label">Ticket sales</span>
              <span class="entry__value danger" v-if="hasTicketSalesClosed"> CLOSED </span>
              <span class="entry__value warning" v-else-if="!hasTicketSalesOpened">
                <span>Opens at </span>
                <span>{{ show.ticketSales.availableFrom | dayjs.utc('MMM DD, YYYY (HH:mm)') }}</span>
              </span>
              <span class="entry__value success" v-else-if="show.ticketSales.availableUntil">
                <span>Closes at </span>
                <span>{{ show.ticketSales.availableUntil | dayjs.utc('MMM DD, YYYY (HH:mm)') }}</span>
              </span>
              <span class="entry__value danger" v-else-if="!show.ticketSales.isAvailable">
                <span>SOLD OUT</span>
              </span>
              <span class="entry__value success" v-else>
                <span>ACTIVE</span>
              </span>
            </summary>

            <p class="entry">
              <span class="entry__label">Ticket price</span>
              <span class="entry__value">
                {{ show.ticketSales.price }}
                <strong>({{ toUsd(show.ticketSales.price) }})</strong>
              </span>
            </p>
            <p class="entry">
              <span class="entry__label">Limit</span>
              <span class="entry__value">
                {{ show.ticketSales.limit }}
                <strong v-if="hasTicketSalesOpened || ticketsLeft !== show.ticketSales.limit">
                  <span>({{ ticketsLeft }} left)</span>
                </strong>
              </span>
            </p>
            <p class="entry" v-if="show.borderControl">
              <span class="entry__label">Early entry limit</span>
              <span class="entry__value">
                {{ show.borderControl.earlyEntryLimit }}
                <strong v-if="earlyEntriesLeft !== show.borderControl.earlyEntryLimit">
                  <span>({{ earlyEntriesLeft }} left)</span>
                </strong>
              </span>
            </p>
          </details>
        </div>
        <div class="show-cell__box" v-if="show.meetAndGreet">
          <details>
            <summary class="entry">
              <span class="entry__label">Secret Gathering</span>
              <template v-if="show.meetAndGreet.isReleased">
                <span class="entry__value danger" v-if="show.meetAndGreet.isSoldOut">SOLD OUT</span>
                <span class="entry__value success" v-else>
                  <span>ON SALE</span>
                  <span> ({{ show.meetAndGreet.numberOfValidTickets }}/{{ show.meetAndGreet.totalQuota }})</span>
                </span>
              </template>
              <template v-else>
                <span class="entry__value success" v-if="show.meetAndGreet.isApplicationsEnabled">
                  APPLICATIONS OPEN
                </span>
                <span class="entry__value danger" v-else>APPLICATIONS CLOSED</span>
              </template>
            </summary>
            <p class="entry">
              <span class="entry__label">Total applicants</span>
              <span class="entry__value">
                <button @click="onShowMeetAndGreetApplications">{{ show.meetAndGreet.applicationsCount }}</button>
              </span>
            </p>
            <p class="entry" v-if="show.meetAndGreet.loyalQuota">
              <span class="entry__label">Loyal quota</span>
              <span class="entry__value">
                <button @click="() => onShowMeetAndGreetTickets('loyal')">
                  {{ show.meetAndGreet.numberOfLoyalTickets }}
                </button>
                <span> / {{ show.meetAndGreet.loyalQuota }}</span>
              </span>
            </p>
            <p class="entry" v-if="show.meetAndGreet.randomQuota">
              <span class="entry__label">Random quota</span>
              <span class="entry__value">
                <button @click="() => onShowMeetAndGreetTickets('random')">
                  {{ show.meetAndGreet.numberOfRandomTickets }}
                </button>
                <span> / {{ show.meetAndGreet.randomQuota }}</span>
              </span>
            </p>
            <p class="entry" v-if="show.meetAndGreet.purchaseQuota">
              <span class="entry__label">Purchase quota</span>
              <span class="entry__value">
                <button @click="() => onShowMeetAndGreetTickets('purchase')">
                  {{ show.meetAndGreet.numberOfTicketsPurchased }}
                </button>
                <span> / {{ show.meetAndGreet.purchaseQuota }}</span>
              </span>
            </p>
            <p class="entry">
              <span class="entry__label">Availability alert threshold</span>
              <span class="entry__value">
                {{ show.meetAndGreet.purchaseQuotaNotificationThreshold }}
              </span>
            </p>
            <p class="entry">
              <span class="entry__label">Price</span>
              <span class="entry__value">
                {{ show.meetAndGreet.price }}
                <strong>({{ toUsd(show.meetAndGreet.price) }})</strong>
              </span>
            </p>
            <template v-if="show.meetAndGreet.isReleased">
              <p class="entry">
                <span class="entry__label">Released at</span>
                <span class="entry__value">
                  {{ show.meetAndGreet.releasedAt | dayjs.utc('MMM DD, YYYY (HH:mm)') }}
                </span>
              </p>
            </template>
            <template v-else>
              <p class="entry">
                <span class="entry__label">Release date</span>
                <span class="entry__value">
                  {{ show.meetAndGreet.releaseDate | dayjs.utc('MMM DD, YYYY (HH:mm)') }}
                </span>
              </p>
            </template>
            <p class="entry" v-if="show.meetAndGreet.instructions">
              <span class="entry__label">Instructions</span>
              <span class="entry__value">
                {{ show.meetAndGreet.instructions }}
              </span>
            </p>
            <base-button class="action" v-if="!show.meetAndGreet.isReleased" @click="onMeetAndGreetRelease">
              Release now
            </base-button>
          </details>
        </div>
        <p class="show-cell__body">
          <a v-if="show.externalTicketLink" :href="show.externalTicketLink" target="_blank">✔️ {{ shortTicketLink }}</a>
        </p>
        <template v-if="show.chat">
          <base-button @click="onToggleChat">{{ showChat ? 'Close' : 'Chat' }}</base-button>
          <div v-if="showChat" class="chat">
            <chat :chatId="show.chat.id" />
          </div>
        </template>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="show-cell__form">
        <base-form-input class="form__input" label="Show date (LOCAL)">
          <base-date-input v-model="form.showDate" type="datetime-local" :min="new Date()" />
        </base-form-input>
        <base-form-input class="form__input" label="Venue name">
          <input v-model="form.addressInput.venue" placeholder="Name of arena" />
        </base-form-input>
        <base-form-input class="form__input" label="City">
          <input v-model="form.addressInput.city" placeholder="City" />
        </base-form-input>
        <base-form-input class="form__input" label="Country">
          <select v-model="form.addressInput.countryId">
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
              :selected="country.id === form.addressInput.countryId"
            >
              {{ country.name }}
            </option>
          </select>
        </base-form-input>
        <base-form-input class="form__input" label="Tour">
          <tour-select v-model="form.tourId" />
        </base-form-input>
        <base-form-input class="form__input" label="Description">
          <app-html-editor v-model="form.description" />
        </base-form-input>
        <base-form-input class="form__input" label="Visible from (UTC)" :optional="true">
          <base-date-input v-model="form.visibleFrom" type="datetime-local" :min="new Date()" :max="form.showDate" />
        </base-form-input>
        <base-checkbox
          v-if="(!show && tour.meetAndGreet) || show?.meetAndGreet"
          :value="showMeetAndGreetForm"
          @update="onMeetAndGreet"
          class="form__input"
        >
          Secret gathering
        </base-checkbox>
        <fieldset class="form__box" v-if="showMeetAndGreetForm && form.meetAndGreetInput">
          <legend>Secret gathering</legend>
          <base-checkbox v-model="form.meetAndGreetInput.isApplicationsEnabled" class="form__input">
            Enable applications
          </base-checkbox>
          <base-form-input class="form__input" label="Loyal quota">
            <input v-model.number="form.meetAndGreetInput.loyalQuota" type="number" placeholder="Limit" />
            <span slot="hint">Top X citizens by score</span>
          </base-form-input>
          <base-form-input class="form__input" label="Random quota">
            <input v-model.number="form.meetAndGreetInput.randomQuota" type="number" placeholder="Limit" />
            <span slot="hint">Random X Citizens</span>
          </base-form-input>
          <base-form-input class="form__input" label="Purchase quota">
            <input v-model.number="form.meetAndGreetInput.purchaseQuota" type="number" placeholder="Limit" />
            <span slot="hint">Ticket quota for purchase</span>
          </base-form-input>
          <base-form-input class="form__input" label="Availability alert threshold">
            <input
              v-model.number="form.meetAndGreetInput.purchaseQuotaNotificationThreshold"
              type="number"
              placeholder="Limit"
            />
            <span slot="hint">Display a notification when X tickets left</span>
          </base-form-input>
          <base-form-input class="form__input" label="Price">
            <input v-model.number="form.meetAndGreetInput.price" type="number" placeholder="Price" />
            <template slot="hint">{{
              form.meetAndGreetInput.price
                ? `= ${toUsd(form.meetAndGreetInput.price)}`
                : 'In USD x 1000, i.e. $100.00 = 100000'
            }}</template>
          </base-form-input>
          <base-form-input class="form__input" label="Release date (UTC)">
            <base-date-input v-model="form.meetAndGreetInput.releaseDate" type="datetime-local" :min="new Date()" />
            <span slot="hint">Loyal citizens will be selected and tickets will go for purchase</span>
          </base-form-input>
          <base-form-input class="form__input" label="Instructions" :optional="true">
            <input
              v-model="form.meetAndGreetInput.instructions"
              placeholder="Instructions for Secret Gathering ticket holders"
            />
            <span slot="hint">Included in email and shown on holders show page</span>
          </base-form-input>
          <base-checkbox v-model="form.meetAndGreetInput.isRemindersEnabled" class="form__input">
            Enable reminders (ticket holders will receive an email 3 days before the show)
          </base-checkbox>
        </fieldset>
        <base-checkbox
          v-if="!show || !show.borderControl"
          :value="showBorderControlForm"
          @update="onBorderControl"
          class="form__input"
        >
          Border control
        </base-checkbox>
        <fieldset class="form__box" v-if="showBorderControlForm && form?.borderControlInput">
          <legend>Border control</legend>
          <base-form-input class="form__input" label="Early entry limit">
            <input v-model.number="form.borderControlInput.earlyEntryLimit" type="number" placeholder="Limit" />
          </base-form-input>
          <base-form-input class="form__input" label="Details" :optional="true">
            <input v-model="form.borderControlInput.details" placeholder="Specific border control details" />
          </base-form-input>
        </fieldset>
        <base-checkbox
          v-if="!show || !show.ticketSales"
          :value="showTicketSalesForm"
          @update="onTicketSales"
          class="form__input"
          >Ticket sales</base-checkbox
        >
        <fieldset class="form__box" v-if="showTicketSalesForm && form?.ticketSalesInput">
          <legend>Ticket sales</legend>
          <base-form-input class="form__input" label="Price">
            <input v-model.number="form.ticketSalesInput.price" type="number" placeholder="Price" />
            <template slot="hint">{{
              form.ticketSalesInput.price
                ? `= ${toUsd(form.ticketSalesInput.price)}`
                : 'In USD x 1000, i.e. $100.00 = 100000'
            }}</template>
          </base-form-input>
          <base-form-input class="form__input" label="Limit">
            <input v-model.number="form.ticketSalesInput.limit" type="number" placeholder="Limit" />
          </base-form-input>
          <base-form-input class="form__input" label="Note" :optional="true">
            <input v-model="form.ticketSalesInput.note" placeholder="General admission" />
          </base-form-input>
          <base-form-input class="form__input" label="Available from (UTC)">
            <base-date-input
              v-model="form.ticketSalesInput.availableFrom"
              type="datetime-local"
              :min="form.visibleFrom"
              :max="form.showDate"
            />
          </base-form-input>
          <base-form-input class="form__input" label="Available until (UTC)">
            <base-date-input
              v-model="form.ticketSalesInput.availableUntil"
              type="datetime-local"
              :min="form.ticketSalesInput.availableFrom"
              :max="form.showDate"
            />
          </base-form-input>
        </fieldset>
        <base-checkbox :value="showChatForm" @update="onToggleChatForm" class="form__input">Has chat</base-checkbox>
        <div v-if="showChatForm && form?.chatInput" class="form__box">
          <base-checkbox v-model="form.chatInput.disabled" class="form__input">Disable new comments</base-checkbox>
        </div>
        <base-form-input class="form__input" label="External ticket link" :optional="true">
          <input v-model="form.externalTicketLink" placeholder="https://www.ticketmaster.com" type="url" />
        </base-form-input>
        <base-form-input class="form__input" label="Attendance validation pattern" :optional="true">
          <input v-model="form.attendanceValidationPattern" placeholder="^[a-zA-Z]{3}\d{3}$" />
        </base-form-input>
      </form>
    </template>
    <meet-and-greet-modal
      v-if="meetAndGreetModal"
      :show-id="show?.id"
      :default-filter="meetAndGreetModal"
      @close="meetAndGreetModal = false"
    />
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import Chat from '@/components/Chat.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import MeetAndGreetModal from '@/components/MeetAndGreetModal.vue';
import TourSelect from '@/components/TourSelect.vue';
import { ChatForm } from '@/forms/chat/ChatForm';
import { CreateShowForm, ShowForm, UpdateShowForm } from '@/forms/show';
import { BorderControlForm } from '@/forms/show/BorderControlForm';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MeetAndGreetForm } from '../forms/show/MeetAndGreetForm';
import { TicketSalesForm } from '../forms/show/TicketSalesForm';
import { Country, DeleteShowInput, ImportedShow, MeetAndGreetQuota, Show, Tour } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor, Chat, MeetAndGreetModal, TourSelect },
  apollo: {
    countries: {
      query: gql`
        query Countries {
          countries {
            id
            name
          }
        }
      `,
    },
  },
})
export default class ShowCell extends Vue {
  @Prop()
  public show?: Show;

  @Prop()
  public data?: ImportedShow;

  @Prop({ required: true })
  public tour!: Tour;

  @Prop({ default: false })
  public focused!: boolean;

  public countries?: Country[];
  public selectedCountry: Country | null = null;
  public showTicketSales = false;
  public showTicketSalesForm = false;
  public showMeetAndGreetForm = false;
  public showBorderControlForm = false;
  public showChat = false;
  public showChatForm = false;

  public get showIsVisible(): boolean {
    return dayjs().isAfter(this.show!.visibleFrom);
  }

  public get shortTicketLink(): string | undefined {
    const link = this.show?.externalTicketLink;
    const baseUrl = link && link.match(/^.+?[^\/:](?=[?\/]|$)/);
    return (baseUrl && baseUrl[0]) || undefined;
  }

  public get ticketsLeft(): number {
    return this.show?.ticketSales ? this.show.ticketSales.limit - this.show.ticketSales.ticketsCount : 0;
  }

  public get earlyEntriesLeft(): number {
    return this.show?.borderControl
      ? this.show.borderControl.earlyEntryLimit - this.show.borderControl.earlyEntryCount
      : 0;
  }

  public get hasTicketSalesClosed(): boolean {
    return this.show?.ticketSales?.hasClosed || this.show?.isPast || false;
  }

  public get hasTicketSalesOpened(): boolean {
    return this.show?.ticketSales?.hasOpened || false;
  }

  public form = null as ShowForm | null;
  public meetAndGreetModal = false as false | 'applications' | MeetAndGreetQuota;

  public mounted() {
    if (!this.show) {
      this.form = this.data
        ? CreateShowForm.fromImport({ ...this.data, tourId: this.tour.id })
        : new CreateShowForm(this.tour.id);
    }
    if (this.focused) {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public toUsd(value?: number) {
    return value != null ? `$${value / 1000}` : null;
  }

  public onEdit() {
    this.form = this.show ? new UpdateShowForm(this.show) : new CreateShowForm(this.tour.id);
    this.showTicketSalesForm = !!this.form.ticketSalesInput;
    this.showBorderControlForm = !!this.form.borderControlInput;
    this.showMeetAndGreetForm = !!this.form.meetAndGreetInput;
    this.showChatForm = !!this.form.chatInput;
  }

  public onTicketSales(include: boolean) {
    if (include) {
      this.form!.ticketSalesInput = new TicketSalesForm();
      this.showTicketSalesForm = true;
    } else {
      this.showTicketSalesForm = false;
      this.form!.ticketSalesInput = null;
    }
  }

  public onBorderControl(include: boolean) {
    if (include) {
      this.form!.borderControlInput = new BorderControlForm();
      this.showBorderControlForm = true;
    } else {
      this.showBorderControlForm = false;
      this.form!.borderControlInput = null;
    }
  }

  public onMeetAndGreet(include: boolean) {
    if (include) {
      this.form!.meetAndGreetInput = MeetAndGreetForm.fromTour(this.tour);
      this.showMeetAndGreetForm = true;
    } else {
      this.showMeetAndGreetForm = false;
      this.form!.meetAndGreetInput = null;
    }
  }

  public onShowMeetAndGreetApplications() {
    this.meetAndGreetModal = 'applications';
  }

  public onShowMeetAndGreetTickets(quota: 'loyal' | 'random' | 'purchase') {
    this.meetAndGreetModal = {
      loyal: MeetAndGreetQuota.Loyal,
      random: MeetAndGreetQuota.Random,
      purchase: MeetAndGreetQuota.Purchase,
    }[quota];
  }

  public onToggleChat() {
    this.showChat = !this.showChat;
  }

  public onToggleChatForm(show: boolean) {
    if (show) {
      this.form!.chatInput = new ChatForm(this.show?.chat || undefined);
      this.showChatForm = true;
    } else {
      this.form!.chatInput = null;
      this.showChatForm = false;
    }
  }

  public onCancel() {
    this.closeForm();
  }

  public async onMeetAndGreetRelease() {
    const confirm = await this.$modal.confirm({
      title: 'Release now?',
      message:
        'Loyal and random citizens will be selected and notified. Tickets will be released for sale. This cannot be undone. Continue?',
    });
    if (!confirm) return;
    await this.$apollo.mutate({
      mutation: gql`
        mutation ReleaseMeetAndGreet($input: ReleaseMeetAndGreetInput!) {
          releaseMeetAndGreet(input: $input) {
            show {
              id
              meetAndGreet {
                isReleased
                releasedAt
              }
            }
          }
        }
      `,
      variables: { input: { showId: this.show!.id } },
      refetchQueries: ['Tour', 'MeetAndGreetUsers'],
    });
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public async onDelete() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteShow($input: DeleteShowInput!) {
          deleteShow(input: $input) {
            success
          }
        }
      `,
      variables: { input: { id: this.show!.id } satisfies DeleteShowInput },
      refetchQueries: ['Tour'],
    });
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  // @Watch('form.addressInput.countryId')
  // private async onCountryChange(countryId: string) {
  //   this.form!.addressInput.administrativeArea =
  //     (this.show && countryId === this.show.address.country.id && this.show.address.administrativeArea) || undefined;
  //   const { data } = await this.$apollo.query<{ country?: Country }>({
  //     query: gql`
  //       query Country($id: String!) {
  //         country(id: $id) {
  //           id
  //           name
  //           provinces {
  //             id
  //             name
  //           }
  //         }
  //       }
  //     `,
  //     variables: {
  //       id: countryId,
  //     },
  //   });
  //   this.selectedCountry = (data && data.country) || null;
  // }
}
</script>

<style lang="scss" scoped>
.show-cell {
  &__title {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    strong {
      margin-left: 8px;
      font-size: 1rem;
    }
  }

  &__tour {
    font-size: 0.9em;
    color: $color-theme-red;
    font-style: italic;
  }

  &__body {
    margin-bottom: 8px;
  }
  &__box {
    width: 500px;
    max-width: 80vw;
    padding: 12px 16px;
    margin-bottom: 8px;
    border: 1px solid $color-theme-red;

    summary {
      font-weight: bold;
      display: list-item !important;
    }

    summary + .entry {
      margin-top: 8px;
    }

    .entry {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: 8px;

      &__value {
        float: right;
        font-weight: bold;
        strong {
          margin-left: 2px;
          font-size: 1rem;
        }

        button {
          background: none;
          border: none;
          color: $color-theme-red;
          cursor: pointer;
          font-size: inherit;
          font-weight: inherit;
          text-decoration: underline;
          padding: 0;
        }

        &.danger {
          color: $color-theme-red;
        }
        &.success {
          color: $color-success;
        }
      }
    }

    .action {
      margin-top: 8px;
    }
  }
  .chat {
    margin-top: 8px;
    max-height: 50vh;
  }

  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .form__box {
      margin-bottom: 12px;
      padding: 12px 16px;
      border: 1px solid $color-theme-red;
    }
  }
}
</style>
