import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { MutationUpdateTourArgs, Tour } from '@/types/api';
import gql from 'graphql-tag';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdateTour($input: UpdateTourInput!) {
        updateTour(input: $input) {
          tour {
            id
            meetAndGreet {
              ticketImageUrls
              defaultTicketPrice
              defaultPurchaseQuota
              defaultPurchaseQuotaNotificationThreshold
              defaultReleaseDate
              defaultInstructions
              defaultLoyalQuota
              defaultRandomQuota
            }
          }
        }
      }
    `,
    refetchQueries: ['Tour'],
  },
})
export class TourMeetAndGreetForm extends GraphQLForm<Tour> {
  private tourId: string;

  @Field()
  public ticketTitle: string;

  @Field()
  public ticketDescription: string;

  @Field()
  public ticketSummary: string;

  @Field({ name: 'ticketImages' })
  public images: Array<File> | null = null;

  @Field()
  public defaultTicketPrice: number | null;

  @Field()
  public defaultPurchaseQuota: number | null;

  @Field()
  public defaultLoyalQuota: number | null;

  @Field()
  public defaultRandomQuota: number | null;

  @Field()
  public defaultPurchaseQuotaNotificationThreshold: number | null;

  @Field()
  public defaultReleaseDate: Date | null;

  @Field()
  public defaultInstructions: string | null;

  constructor(tour: Tour) {
    super(tour);
    this.tourId = tour.id;
    this.ticketTitle = tour.meetAndGreet?.ticketTitle ?? '';
    this.ticketDescription = tour.meetAndGreet?.ticketDescription ?? '';
    this.ticketSummary = tour.meetAndGreet?.ticketSummary ?? '';
    this.defaultTicketPrice = tour.meetAndGreet?.defaultTicketPrice ?? null;
    this.defaultPurchaseQuota = tour.meetAndGreet?.defaultPurchaseQuota ?? null;
    this.defaultPurchaseQuotaNotificationThreshold =
      tour.meetAndGreet?.defaultPurchaseQuotaNotificationThreshold ?? null;
    this.defaultReleaseDate = tour.meetAndGreet?.defaultReleaseDate ?? null;
    this.defaultInstructions = tour.meetAndGreet?.defaultInstructions ?? null;
    this.defaultLoyalQuota = tour.meetAndGreet?.defaultLoyalQuota ?? null;
    this.defaultRandomQuota = tour.meetAndGreet?.defaultRandomQuota ?? null;
  }

  public save() {
    return super.save({
      variables: {
        input: {
          id: this.tourId,
          meetAndGreet: this.filteredFieldsData,
        },
      } satisfies MutationUpdateTourArgs,
    });
  }
}
