import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=173d83db&scoped=true"
import script from "./Admin.vue?vue&type=script&lang=ts"
export * from "./Admin.vue?vue&type=script&lang=ts"
import style0 from "./Admin.vue?vue&type=style&index=0&id=173d83db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173d83db",
  null
  
)

export default component.exports