import { Field } from '../../form/decorators/Field';
import EmbeddedForm from '../../form/EmbeddedForm';
import { MeetAndGreet, Tour } from '../../types/api';

export class MeetAndGreetForm extends EmbeddedForm<MeetAndGreet> {
  @Field()
  public price: number = 0;

  @Field()
  public loyalQuota: number = 2;

  @Field()
  public randomQuota: number = 0;

  @Field()
  public purchaseQuota: number = 8;

  @Field()
  public purchaseQuotaNotificationThreshold: number = 2;

  @Field()
  public releaseDate: Date = new Date();

  @Field()
  public isApplicationsEnabled: boolean = true;

  @Field()
  public isRemindersEnabled: boolean = false;

  @Field()
  public instructions?: string | null;

  static fromTour(tour: Tour): MeetAndGreetForm {
    const form = new MeetAndGreetForm();
    form.price = tour.meetAndGreet?.defaultTicketPrice ?? 0;
    form.randomQuota = tour.meetAndGreet?.defaultRandomQuota ?? 0;
    form.loyalQuota = tour.meetAndGreet?.defaultLoyalQuota ?? 0;
    form.purchaseQuota = tour.meetAndGreet?.defaultPurchaseQuota ?? 0;
    form.purchaseQuotaNotificationThreshold = tour.meetAndGreet?.defaultPurchaseQuotaNotificationThreshold ?? 2;
    form.releaseDate = tour.meetAndGreet?.defaultReleaseDate
      ? new Date(tour.meetAndGreet.defaultReleaseDate)
      : new Date();
    form.instructions = tour.meetAndGreet?.defaultInstructions ?? null;
    return form;
  }

  constructor(meetAndGreet?: MeetAndGreet) {
    super(meetAndGreet);
    if (meetAndGreet) {
      this.price = meetAndGreet.price;
      this.randomQuota = meetAndGreet.randomQuota;
      this.loyalQuota = meetAndGreet.loyalQuota;
      this.purchaseQuota = meetAndGreet.purchaseQuota;
      this.purchaseQuotaNotificationThreshold = meetAndGreet.purchaseQuotaNotificationThreshold;
      this.releaseDate = new Date(meetAndGreet.releaseDate);
      this.instructions = meetAndGreet.instructions;
      this.isApplicationsEnabled = meetAndGreet.isApplicationsEnabled;
      this.isRemindersEnabled = meetAndGreet.isRemindersEnabled;
    }
  }
}
