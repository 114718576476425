<template>
  <base-stack class="gatherings">
    <base-stack direction="row" :spacing="2" v-if="!shouldImport && !shouldAddProduct">
      <base-button @click="shouldAddProduct = true">Add product</base-button>
      <base-button @click="shouldImport = true">Import existing...</base-button>
    </base-stack>
    <base-stack>
      <transition-group name="list" mode="out-in" class="products__list" tag="ul">
        <app-meet-and-greet-product-import-cell
          v-if="shouldImport"
          key="import"
          @import="onImport"
          @cancel="shouldImport = false"
        />
        <app-purchasable-cell
          v-if="shouldAddProduct"
          key="add"
          :data="importData"
          type="meetAndGreet"
          class="list__purchasable"
          @close="shouldAddProduct = false"
        />
        <app-purchasable-cell
          v-for="product in tour?.meetAndGreet?.products"
          :key="product.id"
          type="meetAndGreet"
          :purchasable="product"
          class="list__purchasable"
        />
      </transition-group>
    </base-stack>
  </base-stack>
</template>

<script lang="ts">
import { TourFragments } from '@/apollo/fragments/tour';
import AppMeetAndGreetProductImportCell from '@/components/MeetAndGreetProductImportCell.vue';
import AppPurchasableCell from '@/components/PurchasableCell.vue';
import { MeetAndGreetProduct, Tour } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            ...TourGatherings
          }
        }
        ${TourFragments.TourGatherings}
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  components: { AppPurchasableCell, AppMeetAndGreetProductImportCell },
})
export default class TourGatherings extends Vue {
  @Prop({ required: true })
  public tourId!: string;

  tour?: Tour;
  shouldAddProduct = false;
  shouldImport = false;
  importData: MeetAndGreetProduct | null = null;

  onImport(product: MeetAndGreetProduct) {
    this.importData = product;
    this.shouldAddProduct = true;
    this.shouldImport = false;
  }
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid $color-theme-red;
  padding: 16px;
}

.gatherings {
  & > ul {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
