import { PurchasableFragments } from '@/apollo/fragments/purchasable';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Product } from '@/types/api';
import gql from 'graphql-tag';
import { ProductForm } from '.';
import { VariantForm } from './variant/VariantForm';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdatePurchasable($input: UpdatePurchasableInput!) {
        updatePurchasable(input: $input) {
          purchasable {
            ...Purchasable
          }
        }
      }
      ${PurchasableFragments.Purchasable}
    `,
  },
})
export class UpdateProductForm extends GraphQLForm<Product> implements ProductForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public description: string;

  @Field()
  public price: number;

  @Field()
  public requiredScore: number;

  @Field()
  public isCitizenRestricted: boolean;

  @Field()
  public sortOrder?: number;

  @Field()
  public images: Array<File> | null = null;

  @Field()
  public variantInputs = [new VariantForm()];

  constructor(product: Product) {
    super(product);
    this.id = product.id;
    this.title = product.title;
    this.description = product.description;
    this.price = product.price;
    this.requiredScore = product.requiredScore;
    this.isCitizenRestricted = product.isCitizenRestricted;
    this.sortOrder = product.sortOrder;
    this.variantInputs = product.variants.map((variant) => new VariantForm(variant));
  }
}
