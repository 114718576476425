import gql from 'graphql-tag';
import { PurchasableFragments } from './purchasable';
import { ShowFragments } from './show';

export namespace TourFragments {
  export const TourList = gql`
    fragment TourList on Tour {
      id
      name
      description
      createdAt
      minDate
      maxDate
    }
  `;

  export const TourShows = gql`
    fragment TourShows on Tour {
      id
      name
      description
      createdAt
      meetAndGreet {
        defaultTicketPrice
        defaultInstructions
        defaultReleaseDate
        defaultPurchaseQuota
        defaultLoyalQuota
        defaultRandomQuota
        defaultPurchaseQuotaNotificationThreshold
      }
      shows {
        ...Show
      }
    }
    ${ShowFragments.Show}
  `;

  export const TourSettings = gql`
    fragment TourSettings on Tour {
      id
      name
      description
      createdAt
      meetAndGreet {
        ticketTitle
        ticketDescription
        ticketSummary
        ticketImageUrls
        defaultTicketPrice
        defaultPurchaseQuotaNotificationThreshold
        defaultLoyalQuota
        defaultRandomQuota
        defaultPurchaseQuota
        defaultReleaseDate
        defaultInstructions
      }
      shows {
        id
      }
    }
  `;

  export const TourGatherings = gql`
    fragment TourGatherings on Tour {
      id
      name
      description
      createdAt
      meetAndGreet {
        products {
          ...Purchasable
        }
      }
    }
    ${PurchasableFragments.Purchasable}
  `;

  export const TourStatistics = gql`
    fragment TourStatistics on Tour {
      id
      meetAndGreet {
        ticketsSold
        bundlesSold
        purchasesByVariant {
          variant {
            id
            name
            purchasable {
              id
              title
              imageUrls
            }
          }
          purchases
        }
      }
    }
  `;
}
