<template>
  <app-cell
    class="purchasable-cell"
    :edit="!!form"
    :deletable="
      purchasable && (purchasable.__typename === 'Product' || purchasable.__typename === 'MeetAndGreetProduct')
    "
    :loading="!!form && form.isLoading"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="purchasable">
      <div class="purchasable-cell__content">
        <h2 class="purchasable-cell__title">
          {{ purchasable.title }}
          <strong v-if="'isCitizenRestricted' in purchasable && purchasable.isCitizenRestricted">
            (citizens only!)
          </strong>
        </h2>
        <p class="purchasable-cell__price">
          Price:
          <b>{{ purchasable.price }}</b>
          <strong class="usd">({{ currentUsdPrice }})</strong>
        </p>
        <p class="purchasable-cell__description">
          <slider
            class="purchasable-cell__slider"
            v-if="purchasable.imageUrls.length"
            :images="purchasable.imageUrls"
          />
          <span v-html="purchasable.description"></span>
        </p>
        <template v-if="type === 'meetAndGreet'">
          <div class="purchasable-cell__visibility">
            <strong v-if="purchasable.randomExclusive">❗ Random only</strong>
            <strong v-if="purchasable.loyalExclusive">❗ Loyal only</strong>
          </div>
        </template>
        <ul class="purchasable-cell__variants">
          <h3>Variants</h3>
          <li class="variants__variant" v-for="variant in purchasable.variants" :key="variant.id">
            <p class="variant__name">{{ variant.name }}</p>
            <p class="variant__stock" v-if="variant.outOfStockEU || variant.outOfStockUS">
              <strong v-if="variant.outOfStockEU">Out of stock EU!</strong>
              <strong v-if="variant.outOfStockUS">Out of stock US!</strong>
            </p>
          </li>
        </ul>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="purchasable-cell__form">
        <base-form-input class="form__input" label="Title">
          <input v-model="form.title" placeholder="Title" />
        </base-form-input>
        <base-form-input v-if="'sortOrder' in form && form.sortOrder != null" class="form__input" label="Sort Order:">
          <input v-model.number="form.sortOrder" placeholder="Sort order" type="number" />
        </base-form-input>
        <base-form-input class="form__input" label="Price">
          <input v-model.number="form.price" placeholder="Price" type="number" />
          <template slot="hint">{{
            inputUsdPrice ? `= ${inputUsdPrice}` : 'In USD x 1000, i.e. $100.00 = 100000'
          }}</template>
        </base-form-input>
        <base-form-input label="Product image" class="form__input">
          <input @change="onFileUpload" placeholder="Image" type="file" accept="image/png, image/jpeg" multiple />
        </base-form-input>
        <base-form-input class="form__input" label="Description">
          <app-html-editor v-model="form.description" />
        </base-form-input>
        <template v-if="'isCitizenRestricted' in form">
          <base-checkbox v-model="form.isCitizenRestricted" class="form__input"> Citizens only </base-checkbox>
          <base-form-input
            v-if="'requiredScore' in form && form.isCitizenRestricted"
            class="form__input"
            label="Required Citizen Score (default 0):"
          >
            <input v-model.number="form.requiredScore" placeholder="Required Citizen Score" type="number" />
          </base-form-input>
        </template>

        <base-checkbox v-if="'randomExclusive' in form" v-model="form.randomExclusive" class="form__input">
          Random only
        </base-checkbox>

        <base-checkbox v-if="'loyalExclusive' in form" v-model="form.loyalExclusive" class="form__input">
          Loyal only
        </base-checkbox>

        <ul class="form__variants">
          <app-cell class="variants__variant-form" v-for="variantForm in form.variantInputs" :key="variantForm.key">
            <base-form-input class="form__input" label="Name">
              <input v-model="variantForm.name" placeholder="Name" />
            </base-form-input>
            <base-form-input
              v-if="'sortOrder' in variantForm && form.variantInputs.length > 1"
              class="form__input"
              label="Sort order"
            >
              <input v-model.number="variantForm.sortOrder" placeholder="Sort order" type="number" />
            </base-form-input>
            <base-form-input v-if="'shopifyIdEU' in variantForm" class="form__input" label="EU Shopify">
              <input v-model="variantForm.shopifyIdEU" placeholder="Shopify id (EU)" />
            </base-form-input>
            <base-checkbox v-if="'outOfStockEU' in variantForm" v-model="variantForm.outOfStockEU" class="form__input">
              Out of stock
            </base-checkbox>
            <base-form-input v-if="'shopifyIdUS' in variantForm" label="US Shopify" class="form__input">
              <input v-model="variantForm.shopifyIdUS" placeholder="Shopify id (US)" />
            </base-form-input>
            <base-checkbox v-if="'outOfStockUS' in variantForm" v-model="variantForm.outOfStockUS" class="form__input">
              Out of stock
            </base-checkbox>
            <template slot="actions">
              <base-button @click="onDeleteVariant(variantForm)" :danger="true">Delete</base-button>
            </template>
          </app-cell>
          <li class="variants__add" key="add-variant">
            <base-button @click="onAddVariant">Add variant</base-button>
          </li>
        </ul>
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import Slider from '@/components/Slider.vue';
import {
  CreateMeetAndGreetProductForm,
  CreateProductForm,
  MeetAndGreetProductForm,
  ProductForm,
  PurchasableForm,
  UpdateMeetAndGreetProductForm,
  UpdatePurchasableForm,
} from '@/forms/purchasable';
import { getFilesFromUploadEvent } from '@/utils/getFilesFromUploadEvent';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UpdateProductForm } from '../forms/purchasable/UpdateProductForm';
import { BaseVariantForm } from '../forms/purchasable/variant';
import { MeetAndGreetVariantForm } from '../forms/purchasable/variant/MeetAndGreetVariantForm';
import { VariantForm } from '../forms/purchasable/variant/VariantForm';
import { IPurchasable, MeetAndGreetProduct, Product } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor, Slider },
})
export default class PurchasableCell<TType extends 'meetAndGreet' | 'product' | 'static'> extends Vue {
  @Prop()
  type!: TType;

  @Prop()
  data?: Partial<MeetAndGreetProduct>;

  @Prop()
  purchasable?: TType extends 'meetAndGreet' ? MeetAndGreetProduct : TType extends 'product' ? Product : IPurchasable;

  public get currentUsdPrice(): string {
    const price = this.purchasable ? this.purchasable.price : 0;
    return `$${price / 1000}`;
  }

  public get inputUsdPrice() {
    const price = this.form?.price;
    return price ? `$${price / 1000}` : null;
  }

  public form = null as PurchasableForm<IPurchasable> | ProductForm | MeetAndGreetProductForm | null;

  public onEdit() {
    switch (this.type) {
      case 'meetAndGreet': {
        const tourId = this.$route.params.tourId;
        this.form = this.purchasable
          ? new UpdateMeetAndGreetProductForm(this.purchasable as MeetAndGreetProduct)
          : new CreateMeetAndGreetProductForm(this.data ? { ...this.data, tourId } : { tourId });
        return;
      }
      case 'product': {
        this.form = this.purchasable ? new UpdateProductForm(this.purchasable as Product) : new CreateProductForm();
        return;
      }
      case 'static': {
        this.form = this.purchasable ? new UpdatePurchasableForm(this.purchasable) : new CreateProductForm();
        return;
      }
    }
  }

  public onCancel() {
    this.closeForm();
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public onFileUpload(e: HTMLFileUploadEvent) {
    this.form!.images = getFilesFromUploadEvent(e);
  }

  public async onDelete() {
    switch (this.type) {
      case 'meetAndGreet': {
        await this.$apollo.mutate({
          mutation: gql`
            mutation DeleteMeetAndGreetProduct($input: DeleteMeetAndGreetProductInput!) {
              deleteMeetAndGreetProduct(input: $input) {
                success
              }
            }
          `,
          variables: { input: { id: this.purchasable!.id } },
          refetchQueries: ['Tour'],
        });
        break;
      }
      case 'product': {
        await this.$apollo.mutate({
          mutation: gql`
            mutation DeleteProduct($input: DeleteProductInput!) {
              deleteProduct(input: $input) {
                success
              }
            }
          `,
          variables: { input: { id: this.purchasable!.id } },
          refetchQueries: ['Products'],
        });
        break;
      }
      case 'static': {
        throw new Error('Cannot delete non-product purchasables');
      }
    }
  }

  public onAddVariant() {
    const form = this.form;
    if (form) {
      if (form instanceof CreateMeetAndGreetProductForm || form instanceof UpdateMeetAndGreetProductForm) {
        form.variantInputs.push(new MeetAndGreetVariantForm());
      } else {
        form.variantInputs.push(new VariantForm());
      }
    }
  }

  public onDeleteVariant(variantForm: BaseVariantForm) {
    if (this.form) {
      this.form.variantInputs = this.form.variantInputs.filter((variant) => variantForm.id !== variant.id);
    }
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.purchasable) {
      switch (this.type) {
        case 'meetAndGreet': {
          const tourId = this.$route.params.tourId;
          this.form = new CreateMeetAndGreetProductForm(this.data ? { ...this.data, tourId } : { tourId });
          return;
        }
        case 'product': {
          this.form = new CreateProductForm();
          return;
        }
        case 'static': {
          throw new Error('Cannot create static purchasables');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.purchasable-cell {
  &__title {
    strong {
      margin-left: 8px;
      font-size: 1rem;
    }
  }
  &__price {
    margin-bottom: 16px;
    b {
      font-weight: bold;
    }
    strong {
      margin-left: 8px;
    }
  }
  &__image,
  &__slider {
    height: 220px;
    width: 240px;
    max-width: 400px;
    margin-right: 16px;
    float: left;
  }
  &__image {
    object-fit: contain;
  }
  &__description {
  }
  &__visibility {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  &__variants {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    h3 {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .variants__variant {
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid $color-theme-red;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      .variant__stock {
        strong {
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .form__variants {
      margin-top: 8px;
      .variants__variant-form {
        /* padding: 8px 12px;
        display: flex;
        flex-direction: column; */
        border: 1px solid $color-theme-red;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        strong {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
