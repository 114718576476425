import Vue from 'vue';
import Router from 'vue-router';
import Admin from './layouts/Admin.vue';
import { adminGuard, loginGuard, toursGuard } from './middleware/guards';
import Cinema from './views/Cinema.vue';
import Dashboard from './views/Dashboard.vue';
import Documents from './views/Documents.vue';
import Login from './views/Login.vue';
import News from './views/News.vue';
import Questions from './views/Questions.vue';
import Store from './views/Store.vue';
import Studio from './views/Studio.vue';
import TourView from './views/Tour.vue';
import TourBundle from './views/TourBundle.vue';
import TourGatherings from './views/TourGatherings.vue';
import TourSettings from './views/TourSettings.vue';
import TourShows from './views/TourShows.vue';
import TourStatistics from './views/TourStatistics.vue';
import Users from './views/Users.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: loginGuard,
    },
    {
      path: '/',
      name: 'admin',
      component: Admin,
      beforeEnter: adminGuard,
      redirect: '/dashboard',
      children: [
        { path: '/dashboard', name: 'dashboard', component: Dashboard },
        { path: '/news', name: 'news', component: News },
        { path: '/questions', name: 'questions', component: Questions },
        { path: '/cinema', name: 'cinema', component: Cinema },
        { path: '/studio', name: 'studio', component: Studio },
        { path: '/store', name: 'store', component: Store },
        { path: '/tours', name: 'tours', beforeEnter: toursGuard },
        {
          path: '/tours/:tourId',
          name: 'tour',
          component: TourView,
          props: true,
          children: [
            { path: 'shows', name: 'shows', component: TourShows, props: true },
            { path: 'settings', name: 'settings', component: TourSettings, props: true },
            { path: 'gatherings', name: 'gatherings', component: TourGatherings, props: true },
            { path: 'bundle', name: 'bundle', component: TourBundle, props: true },
            { path: 'statistics', name: 'statistics', component: TourStatistics, props: true },
          ],
          redirect: '/tours/:tourId/shows',
        },
        { path: '/docs', name: 'Documents', component: Documents },
        { path: '/users', name: 'users', component: Users },
      ],
    },
  ],
});
