<template>
  <app-cell :editable="false">
    <div class="import__form">
      <base-form-input label="Import from">
        <tour-select v-model="tourId" :filter="isNotCurrentTour" />
      </base-form-input>
      <base-form-input label="Product" v-if="tour">
        <select v-model="productId">
          <option v-for="product in tour?.meetAndGreet?.products" :key="product.id" :value="product.id">
            {{ product.title }}
          </option>
        </select>
      </base-form-input>
    </div>
    <template slot="actions">
      <base-button @click="onImport">Import</base-button>
      <base-button @click="$emit('cancel')">Cancel</base-button>
    </template>
  </app-cell>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TourFragments } from '../apollo/fragments/tour';
import { Tour } from '../types/api';
import AppCell from './Cell.vue';
import TourSelect from './TourSelect.vue';

@Component({
  name: 'MeetAndGreetProductImportCell',
  components: {
    AppCell,
    TourSelect,
  },
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            ...TourGatherings
          }
        }
        ${TourFragments.TourGatherings}
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      skip() {
        return !this.tourId;
      },
    },
  },
})
export default class MeetAndGreetProductImportCell extends Vue {
  public tour?: Tour;
  public tourId = null as string | null;
  public productId = null as string | null;

  @Watch('tourId', { immediate: true })
  public onTourIdChange(newValue: string | null) {
    if (newValue) {
      this.$apollo.queries.tour?.refetch();
    }
  }

  onImport() {
    const selectedProduct = this.tour?.meetAndGreet?.products.find((product) => product.id === this.productId);
    if (selectedProduct) {
      this.$emit('import', selectedProduct);
    }
  }

  public isNotCurrentTour(tour: Tour): boolean {
    return tour.id !== this.$route.params.tourId;
  }
}
</script>

<style lang="scss" scoped>
.import__form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 600px;
}
</style>
