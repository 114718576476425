<template>
  <div
    class="slider"
    @keydown.left="showPreviousSlide"
    @keydown.right="showNextSlide"
    tabindex="0"
    role="region"
    aria-label="Image slider"
  >
    <div class="slider__container" ref="container">
      <template v-for="(image, index) in images">
        <div :key="index" class="slider__slide">
          <img :src="image" :alt="`Slide ${index + 1}`" loading="lazy" />
        </div>
      </template>
    </div>

    <template v-if="images.length > 1">
      <div v-if="slideIndex > 0" class="arrow-wrap arrow-wrap--left">
        <arrow-right height="24px" width="40px" class="arrow arrow--left" @click="showPreviousSlide" />
      </div>

      <div v-if="slideIndex < images.length - 1" class="arrow-wrap arrow-wrap--right">
        <arrow-right height="24px" width="40px" class="arrow" @click="showNextSlide" />
      </div>

      <div class="slider__dots">
        <button
          v-for="(_, index) in images"
          :key="index"
          class="slider__dot"
          :class="{ active: index === slideIndex }"
          @click="goToSlide(index)"
          :aria-label="`Go to slide ${index + 1}`"
          :aria-current="index === slideIndex"
        ></button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import ArrowRight from '@/assets/arrow.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Slider',
  components: { ArrowRight },
})
export default class Slider extends Vue {
  @Prop({
    default: () => [],
  })
  images!: string[];

  public slideIndex = 0;

  private observer: IntersectionObserver | null = null;

  mounted() {
    this.setupIntersectionObserver();
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private setupIntersectionObserver() {
    const options = {
      root: this.$refs.container as Element,
      threshold: 0.5,
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const slideElement = entry.target as HTMLElement;
          const index = Array.from(slideElement.parentElement?.children || []).indexOf(slideElement);
          if (index !== -1) {
            this.slideIndex = index;
          }
        }
      });
    }, options);

    // Observe all slides
    const slides = (this.$refs.container as Element).querySelectorAll('.slider__slide');
    slides.forEach((slide) => this.observer?.observe(slide));
  }

  private showPreviousSlide() {
    if (this.slideIndex > 0) {
      this.slideIndex--;
      this.scrollToSlide(this.slideIndex);
    }
  }

  private showNextSlide() {
    if (this.slideIndex < this.images.length - 1) {
      this.slideIndex++;
      this.scrollToSlide(this.slideIndex);
    }
  }

  private goToSlide(index: number) {
    this.slideIndex = index;
    this.scrollToSlide(index);
  }

  private scrollToSlide(index: number) {
    const container = this.$refs.container as Element;
    const slides = container.querySelectorAll('.slider__slide');
    if (slides[index]) {
      slides[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  position: relative;
  overflow: hidden;

  &__container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__slide {
    display: flex;
    flex: 0 0 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.arrow-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 12px;
  opacity: 0;
  transition: opacity 0.3s ease;

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  .slider:hover & {
    opacity: 1;
  }
}

.arrow {
  width: 40px;
  height: 24px;
  filter: drop-shadow(0 0 2px black);
  cursor: pointer;
  color: white;

  &--left {
    transform: rotate(180deg);
  }

  &:active {
    filter: drop-shadow(0 0 8px black);
  }
}

.slider__dots {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 2;
}

.slider__dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;

  &.active {
    background-color: #fff;
    transform: scale(1.2);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
}

@media (max-width: 768px) {
  .slider {
    .arrow {
      width: 32px;
      height: 20px;
    }

    &__dot {
      width: 6px;
      height: 6px;
    }
  }
}
</style>
