import gql from 'graphql-tag';

export namespace UserFragments {
  export const User = gql`
    fragment User on User {
      id
      username
      email
      stripeId
      verified
      createdAt
      updatedAt
      citizenshipExpiresAt
      citizenSince
      citizenshipRemindedAt
      role
      name
      phone
      wallet {
        balance
        transactions {
          __typename
          id
          credits
          date

          ... on Ticket {
            refundedAt
            show {
              id
              showDate
              address {
                venue
              }
            }
          }

          ... on Deposit {
            creditsRefunded
            creditsRefundable
            amountInCents
            currency
            receiptUrl
            refunds {
              id
              credits
              amountInCents
              date
            }
          }

          ... on Order {
            refundedAt
            orderItems {
              id
              variant {
                id
                name
              }
            }

            orderFulfillments {
              id
            }
          }

          ... on MeetAndGreetTicketPurchase {
            type
            refundedAt
            quantity
            total
            ticket {
              id
              quota
              show {
                id
                showDate
                address {
                  venue
                }
              }
            }
          }
        }
      }
      score {
        total
      }
      hasPassport
      address {
        city
        administrativeArea
        country {
          id
          name
        }
        name
        postalCode
        firstAddressLine
        secondAddressLine
        phone
      }
      locale
      token
      imageUrl
      orders {
        id
      }
      remarks {
        id
        comment
        createdAt
        author {
          id
          username
        }
      }
    }
  `;
}
