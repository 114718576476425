<template>
  <app-cell
    class="movie-cell"
    :edit="!!form"
    :deletable="!!movie"
    :loading="!!form && form.isLoading"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="movie">
      <div class="movie-cell__content">
        <h2 class="movie-cell__title">
          {{ movie.title }}
          <strong v-if="!movie.publishedAt">(DRAFT)</strong>
          <strong v-else-if="movie.video.isCitizenRestricted">(citizens only!)</strong>
        </h2>
        <p class="movie-cell__key">
          Amazon storage key:
          <a :href="movie.video.url" target="_blank">{{ movie.video.fileKey }}</a>
          <span>{{ ' (' }}</span>
          <a @click="$emit('watch')">MUX</a>
          <span>)</span>
        </p>
        <p class="movie-cell__key">
          Length:
          <span v-if="movie.video.asset.durationInSeconds">{{ movie.video.asset.durationInSeconds | duration }}</span>
          <span v-else>Unknown</span>
        </p>
        <p class="movie-cell__body">
          <img class="movie-cell__image" v-if="movie.imageUrl" :src="movie.imageUrl" :alt="movie.title" />
          <span v-html="movie.body" />
        </p>
        <div class="movie-cell__subtitles">
          <div>
            <base-button v-if="!shouldAddSubtitle" @click="shouldAddSubtitle = true">Add subtitle</base-button>
          </div>
          <subtitle-cell v-if="shouldAddSubtitle" :assetId="movie.video.asset.id" @close="shouldAddSubtitle = false" />
          <subtitle-cell
            v-for="subtitle in movie.video.asset.subtitles"
            :key="subtitle.id"
            :subtitle="subtitle"
            :assetId="movie.video.asset.id"
          />
        </div>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="movie-cell__form">
        <ul class="form__errors" v-if="form.errorMessages">
          <li v-for="error in form.errorMessages" class="error" :key="error">
            {{ error }}
          </li>
        </ul>
        <base-form-input class="form__input" label="Movie title">
          <input v-model="form.title" placeholder="Movie title" />
        </base-form-input>
        <base-form-input class="form__input" v-if="form.sortOrder != null" label="Sort order">
          <input v-model.number="form.sortOrder" placeholder="Sort order" type="number" />
        </base-form-input>
        <base-form-input class="form__input" label="Cover photo">
          <input @change="onFileUpload" placeholder="Cover photo" type="file" accept="image/png, image/jpeg" />
        </base-form-input>
        <base-form-input class="form__input" label="Body">
          <app-html-editor v-model="form.body" />
        </base-form-input>
        <p class="form__description">
          Upload the video to Amazon S3 in root bucket
          <i>('avatar-country-dev' for test environment)</i>. Use the file name below, and save form at which point MUX
          starts doing it's compression.
        </p>
        <base-form-input class="form__input" label="Name on Amazon S3">
          <input v-model="form.videoForm.fileKey" placeholder="Name on Amazon S3" />
        </base-form-input>
        <base-checkbox v-model="form.videoForm.isCitizenRestricted" class="form__input">Citizens only</base-checkbox>
        <base-checkbox v-model="form.published" class="form__input">Published</base-checkbox>
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import SubtitleCell from '@/components/SubtitleCell.vue';
import { CreateMovieForm, MovieForm, UpdateMovieForm } from '@/forms/movie';
import { getFilesFromUploadEvent } from '@/utils/getFilesFromUploadEvent';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Movie } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor, SubtitleCell },
})
export default class MovieCell extends Vue {
  @Prop()
  movie?: Movie;

  public form = null as MovieForm | null;
  public shouldAddSubtitle = false;

  public onEdit() {
    this.form = this.movie ? new UpdateMovieForm(this.movie) : new CreateMovieForm();
  }

  public onCancel() {
    this.closeForm();
  }

  public onFileUpload(e: HTMLFileUploadEvent) {
    const files = getFilesFromUploadEvent(e);
    this.form!.image = files[0];
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public async onDelete() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteMovie($input: DeleteMovieInput!) {
          deleteMovie(input: $input) {
            success
          }
        }
      `,
      variables: { input: { id: this.movie!.id } },
      refetchQueries: ['Movies'],
    });
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.movie) {
      this.form = new CreateMovieForm();
    }
  }
}
</script>

<style lang="scss" scoped>
.movie-cell {
  &__title {
    strong {
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }
  &__key {
    font-weight: bold;
    margin-bottom: 16px;
  }
  &__body {
    :deep(*) {
      margin: 0;
      line-height: 1.4em;
      padding: 8px 0;
    }
  }
  &__image {
    height: 220px;
    max-width: 400px;
    margin-right: 16px;
    float: left;
  }
  &__subtitles {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .form__description {
      margin-bottom: 12px;
      i {
        font-style: italic;
      }
    }
    .form__errors {
      font-weight: bold;
      color: $color-theme-red;
      margin-bottom: 8px;
      line-height: 2em;
    }
  }
}
</style>
