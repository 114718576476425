import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { MeetAndGreetProduct } from '@/types/api';
import gql from 'graphql-tag';
import { MeetAndGreetProductForm } from '.';
import { PurchasableFragments } from '../../apollo/fragments/purchasable';
import { MeetAndGreetVariantForm } from './variant/MeetAndGreetVariantForm';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateMeetAndGreetProduct($input: CreateMeetAndGreetProductInput!) {
        createMeetAndGreetProduct(input: $input) {
          product {
            ...Purchasable
          }
        }
      }
      ${PurchasableFragments.Purchasable}
    `,
    refetchQueries: ['Tour'],
  },
})
export class CreateMeetAndGreetProductForm extends GraphQLForm<MeetAndGreetProduct> implements MeetAndGreetProductForm {
  @Field()
  public title = '';

  @Field()
  public description = '';

  @Field()
  public price?: number;

  @Field()
  public tourId: string;

  @Field()
  public images: Array<File> | null = null;

  @Field()
  public loyalExclusive = true;

  @Field()
  public randomExclusive = true;

  @Field()
  public variantInputs;

  constructor(data: Partial<MeetAndGreetProduct> & { tourId: string }) {
    super();
    this.tourId = data.tourId;
    this.title = data.title ?? '';
    this.description = data.description ?? '';
    this.price = data.price;
    this.loyalExclusive = data.loyalExclusive ?? true;
    this.randomExclusive = data.randomExclusive ?? true;
    this.variantInputs = data.variants?.map((variant) => new MeetAndGreetVariantForm(variant)) ?? [
      new MeetAndGreetVariantForm(),
    ];
  }
}
