<template>
  <modal class="modal" v-if="data" :title="data.title" @close="() => onClose()">
    <template slot="body">
      <p>{{ data.message }}</p>
      <template v-if="'prompt' in data">
        <form id="prompt" @submit.prevent="onConfirm">
          <base-form-input :label="data.prompt.label" class="modal__input">
            <input v-model="prompt" :placeholder="data.prompt.placeholder" />
          </base-form-input>
        </form>
      </template>
    </template>
    <template slot="footer">
      <template v-if="type === 'confirm'">
        <base-button class="modal__button" @click="onCancel">Cancel</base-button>
        <base-button class="modal__button" @click="onConfirm" :danger="true">Confirm</base-button>
      </template>
      <template v-else-if="type === 'alert'">
        <base-button class="modal__button" @click="onClose">Close</base-button>
      </template>
      <template v-else-if="type === 'prompt'">
        <base-button class="modal__button" @click="onCancel">Cancel</base-button>
        <base-button type="submit" form="prompt" class="modal__button">Continue</base-button>
      </template>
    </template>
  </modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ModalData, ModalType, PromptModalData } from '../plugins/modal';
import Modal from './Modal.vue';

@Component({
  name: 'AppModal',
  components: {
    Modal,
  },
})
export default class AppModal extends Vue {
  type: ModalType | null = null;
  data: ModalData | PromptModalData | null = null;

  prompt = '';

  mounted() {
    this.$modal.eventBus.$on('open', this.onOpen);
  }

  onOpen(type: ModalType, modalData: ModalData) {
    this.type = type;
    this.data = modalData;
  }

  onClose() {
    this.close(false);
  }

  onCancel() {
    this.close(false);
  }

  onConfirm() {
    this.close(this.prompt || true);
  }

  private close(callbackData: any) {
    this.$modal.close(callbackData);
    this.data = null;
    this.type = null;
    this.prompt = '';
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__input {
    margin-top: 16px;
  }

  &__button {
    margin-left: 8px;
  }
}
</style>
