<template>
  <table class="purchases">
    <thead>
      <tr>
        <th></th>
        <th>Product</th>
        <th>Variant</th>
        <th>Purchases</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="group in tour?.meetAndGreet?.purchasesByVariant" :key="group.variant.id">
        <td>
          <img :src="group.variant.purchasable?.imageUrls[0]" :alt="group.variant.purchasable?.title" />
        </td>
        <td>{{ group.variant.purchasable?.title }}</td>
        <td>{{ group.variant.name }}</td>
        <td class="purchases">{{ group.purchases }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Tour } from '@/types/api';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MeetAndGreetPurchasesTable extends Vue {
  @Prop({ required: true })
  tour!: Tour;
}
</script>

<style lang="scss" scoped>
table.purchases {
  thead tr,
  tbody tr:not(:last-child) {
    border-bottom: 1px solid $color-theme-yellow;
  }

  thead {
    font-weight: bold;

    tr {
      th {
        padding: 8px;
        text-align: left;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
        padding: 0 8px;

        &:has(img) {
          padding: 0;
          width: 40px;
        }

        &:last-child {
          text-align: right;
        }
      }

      img {
        margin-top: 4px;
        height: 32px;
        width: 32px;
        object-fit: fit;
      }

      .purchases {
        color: $color-theme-red;
        font-weight: bold;
      }
    }
  }
}
</style>
