<template>
  <select v-bind="$attrs" v-on="$listeners" :value="value" @input="onInput">
    <option v-for="tour in filteredTours" :key="tour.id" :value="tour.id" :selected="tour.id === value">
      {{ tour.name }}
    </option>
  </select>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { tourStore } from '../store/tour';
import { Tour } from '../types/api';

@Component({
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  // Change the v-model event name to `update` to avoid changing
  // the behavior of the native `input` event.
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  model: {
    event: 'update',
  },
  apollo: {
    tours: {
      query: gql`
        query Tours {
          tours {
            id
            name
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class TourSelect extends Vue {
  @Prop()
  public value?: string | number;

  @Prop()
  public filter?: (tour: Tour) => boolean;

  private tours: Tour[] = [];

  public get filteredTours(): Tour[] {
    return this.filter ? this.tours.filter(this.filter) : this.tours;
  }

  onInput(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.$emit('update', target.value);
  }

  @Watch('tours')
  public onToursChange(newTours: Tour[]): void {
    if (!this.value) {
      const filteredTours = this.filter ? newTours.filter(this.filter) : newTours;
      const persistedTourId = tourStore.get();
      const tour = (persistedTourId && filteredTours.find((tour) => tour.id === persistedTourId)) || filteredTours[0];
      if (!tour) return;
      this.$emit('update', tour.id);
    }
  }
}
</script>

<style lang="scss" scoped></style>
