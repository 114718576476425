<template>
  <app-cell
    class="remark-cell"
    :edit="!!form"
    :deletable="!!remark"
    :loading="!!form && form.isLoading"
    :editable="canEdit"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="remark">
      <div class="remark-cell__content">
        <div class="remark-cell__header">
          <span class="remark-cell__author">{{ remark.author.username }}</span>
          <span class="remark-cell__date">{{ formatDate(remark.createdAt) }}</span>
        </div>
        <div class="remark-cell__body">{{ remark.comment }}</div>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent="onSave" class="remark-cell__form">
        <base-form-input class="form__input" label="Remark">
          <textarea v-model="form.comment" placeholder="Write your comment..." rows="4"></textarea>
        </base-form-input>
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import { CreateUserRemarkForm, UpdateUserRemarkForm, UserRemarkForm } from '@/forms/userRemark';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserRemark, UserRole } from '../types/api';

@Component({
  components: { AppCell },
  apollo: {
    viewer: {
      query: gql`
        query Viewer {
          viewer {
            id
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class UserRemarkCell extends Vue {
  @Prop()
  remark?: UserRemark;

  @Prop({ required: true })
  targetUserId!: string;

  public form = null as UserRemarkForm | null;
  public viewer: { id: string; role: UserRole } | null = null;

  get canEdit(): boolean {
    if (!this.remark) return true; // Allow creating new remarks
    return Boolean(this.viewer && this.viewer.id === this.remark.author.id);
  }

  public onEdit() {
    this.form = this.remark ? new UpdateUserRemarkForm(this.remark) : new CreateUserRemarkForm();

    if (!this.remark) {
      this.form.targetUserId = this.targetUserId;
    }
  }

  public onCancel() {
    this.closeForm();
  }

  public async onSave() {
    if (!this.form) return;
    await this.form.save();
    this.closeForm();
  }

  public async onDelete() {
    if (!this.remark) return;
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeleteUserRemark($input: DeleteUserRemarkInput!) {
          deleteUserRemark(input: $input) {
            success
          }
        }
      `,
      variables: { input: { id: this.remark.id } },
      refetchQueries: ['User'],
    });
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  private formatDate(date: string) {
    return new Date(date).toLocaleDateString();
  }

  public mounted() {
    if (!this.remark) {
      this.form = new CreateUserRemarkForm();
      this.form.targetUserId = this.targetUserId;
    }
  }
}
</script>

<style lang="scss" scoped>
.remark-cell {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__author {
    font-weight: bold;
  }

  &__date {
    color: $color-disabled;
    font-size: 0.9em;
  }

  &__body {
    font-size: 1rem;
    white-space: pre-wrap;
  }

  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
