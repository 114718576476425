<template>
  <app-cell
    class="post-cell"
    :edit="!!form"
    :deletable="!!post"
    :loading="!!form && form.isLoading"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
    @delete="onDelete"
  >
    <template slot="content" v-if="post">
      <div class="post-cell__content">
        <h2 class="post-cell__title">
          {{ post.title }}
          <strong v-if="post.isNotification">(notification!)</strong>
        </h2>
        <div class="post-cell__body">
          <img class="post-cell__image" v-if="post.imageUrl" :src="post.imageUrl" :alt="post.title" />
          <div v-html="post.html"></div>
        </div>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="post-cell__form">
        <base-form-input class="form__input" label="Title">
          <input v-model="form.title" placeholder="Title" />
        </base-form-input>
        <base-form-input class="form__input" label="Image">
          <input @change="onFileUpload" placeholder="Image" type="file" accept="image/png, image/jpeg" />
        </base-form-input>
        <base-checkbox v-model="form.isNotification" class="form__input">Should notify users</base-checkbox>
        <app-html-editor v-model="form.html" />
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import { CreatePostForm, PostForm, UpdatePostForm } from '@/forms/post';
import { getFilesFromUploadEvent } from '@/utils/getFilesFromUploadEvent';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Post } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor },
})
export default class PostCell extends Vue {
  @Prop()
  post?: Post;

  public form = null as PostForm | null;

  public onEdit() {
    this.form = this.post ? new UpdatePostForm(this.post) : new CreatePostForm();
  }

  public onCancel() {
    this.closeForm();
  }

  public onFileUpload(e: HTMLFileUploadEvent) {
    const files = getFilesFromUploadEvent(e);
    this.form!.image = files[0];
  }

  public async onSave() {
    await this.form!.save();
    this.closeForm();
  }

  public async onDelete() {
    await this.$apollo.mutate({
      mutation: gql`
        mutation DeletePost($input: DeletePostInput!) {
          deletePost(input: $input) {
            success
          }
        }
      `,
      variables: { input: { id: this.post!.id } },
      refetchQueries: ['Posts'],
    });
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }

  public mounted() {
    if (!this.post) {
      this.form = new CreatePostForm();
    }
  }
}
</script>

<style lang="scss" scoped>
.post-cell {
  &__title {
    strong {
      margin-left: 8px;
      font-size: 1rem;
    }
  }
  &__image {
    height: 220px;
    max-width: 400px;
    margin-right: 16px;
    float: left;
  }
  &__body {
    font-size: 1rem;
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
