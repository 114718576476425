<template>
  <base-stack class="tour" v-if="tour">
    <base-stack as="header" direction="column">
      <base-stack direction="row" justify="space-between">
        <h2>{{ tour.name }}</h2>
        <base-stack direction="row">
          <tour-select :value="tourId" @update="onTourChange" />
          <base-button @click="createNewTour">Create Tour</base-button>
        </base-stack>
      </base-stack>
      <base-stack as="nav" direction="row">
        <base-button :to="{ name: 'shows' }">Shows</base-button>
        <base-button v-if="tour.meetAndGreet" :to="{ name: 'gatherings' }">Products</base-button>
        <base-button v-if="tour.meetAndGreet" :to="{ name: 'bundle' }">Bundle</base-button>
        <base-button :to="{ name: 'settings' }">Settings</base-button>
        <base-button :to="{ name: 'statistics' }">Statistics</base-button>
      </base-stack>
    </base-stack>
    <router-view />
  </base-stack>
</template>

<script lang="ts">
import { TourFragments } from '@/apollo/fragments/tour';
import AppCell from '@/components/Cell.vue';
import AppPurchasableCell from '@/components/PurchasableCell.vue';
import AppShowCell from '@/components/ShowCell.vue';
import TourSelect from '@/components/TourSelect.vue';
import { tourStore } from '@/store/tour';
import { Mutation, MutationCreateTourArgs, Tour } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { AppShowCell, AppPurchasableCell, AppCell, TourSelect },
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            ...TourSettings
          }
        }
        ${TourFragments.TourSettings}
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
    tours: {
      query: gql`
        query Tours {
          tours {
            ...TourList
          }
        }
        ${TourFragments.TourList}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class TourView extends Vue {
  @Prop({ required: true })
  public tourId!: string;

  tour?: Tour;
  tours: Tour[] = [];

  onTourChange(tourId: string): void {
    tourStore.set(tourId);
    this.$router.push({ name: 'tour', params: { tourId } });
  }

  async createNewTour(): Promise<void> {
    const tourName = await this.$modal.prompt({
      title: 'Create New Tour',
      message: 'Please enter the name for the new tour.',
      prompt: {
        label: 'Tour Name',
        placeholder: 'Enter tour name...',
      },
    });

    if (!tourName) return;

    const result = await this.$apollo.mutate<{ createTour: Mutation['createTour'] }>({
      mutation: gql`
        mutation CreateTour($input: CreateTourInput!) {
          createTour(input: $input) {
            tour {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          name: tourName,
        },
      } satisfies MutationCreateTourArgs,
      refetchQueries: ['Tours', 'Tour'],
    });

    if (result.data) {
      this.$router.push({ name: 'tour', params: { tourId: result.data.createTour.tour.id } });
    }
  }
}
</script>

<style lang="scss" scoped>
.tour {
  h2 {
    color: $color-theme-red;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }

  header {
    padding: 1rem 0;
    border-bottom: 1px solid $color-theme-yellow;
  }
}
</style>
