<template>
  <router-link
    v-if="to"
    :to="to"
    class="button"
    :class="[size, { 'button--danger': danger, loading }]"
    v-on="$listeners"
  >
    {{ label }}
    <slot />
  </router-link>
  <component v-else :is="as" class="button" :class="[size, { 'button--danger': danger, loading }]" v-on="$listeners">
    {{ label }}
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: true,
})
export default class BaseButton extends Vue {
  @Prop({
    default: 'button',
  })
  as!: string;

  @Prop()
  to?: string;

  @Prop()
  label?: string;

  @Prop({ default: 'md' })
  size!: 'sm' | 'md';

  @Prop({
    default: false,
  })
  loading!: boolean;

  @Prop({
    default: false,
  })
  danger!: boolean;
}
</script>

<style lang="scss" scoped>
.button {
  border: 1px solid $color-theme-yellow;
  padding: 8px;
  background: none;
  color: $color-theme-yellow;
  transition: all 0.1s ease;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    color: $color-theme-red;
    background-color: $color-theme-yellow;
  }
  &:disabled {
    color: $color-disabled !important;
    border-color: $color-disabled !important;
    cursor: not-allowed;
  }
  &--danger {
    color: $color-theme-red;
    border-color: $color-theme-red;
  }
  &.loading {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.sm {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
  &.md {
    padding: 8px 12px;
  }

  &.router-link-exact-active {
    border-color: $color-theme-red;
    color: $color-theme-red;
  }
}
</style>
