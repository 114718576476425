import { ShowFragments } from '@/apollo/fragments/show';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { Show } from '@/types/api';
import gql from 'graphql-tag';
import { ShowForm } from '.';
import { ChatForm } from '../chat/ChatForm';
import { BorderControlForm } from './BorderControlForm';
import { MeetAndGreetForm } from './MeetAndGreetForm';
import { ShowAddressForm } from './ShowAddressForm';
import { TicketSalesForm } from './TicketSalesForm';

@Form({
  apollo: {
    mutation: gql`
      mutation Update($input: UpdateShowInput!) {
        updateShow(input: $input) {
          show {
            ...Show
          }
        }
      }
      ${ShowFragments.Show}
    `,
    refetchQueries: ['Tour'],
  },
})
export class UpdateShowForm extends GraphQLForm<Show> implements ShowForm {
  @Field({ static: true })
  public id: string;

  @Field()
  public description: string | null;

  @Field()
  public tourId: string;

  @Field()
  public showDate: Date;

  @Field()
  public visibleFrom: Date;

  @Field()
  public externalTicketLink?: string | null;

  @Field()
  public attendanceValidationPattern?: string | null;

  @Field()
  public addressInput: ShowAddressForm;

  @Field()
  public ticketSalesInput: TicketSalesForm | null = null;

  @Field()
  public borderControlInput: BorderControlForm | null = null;

  @Field()
  public meetAndGreetInput: MeetAndGreetForm | null = null;

  @Field()
  public chatInput: ChatForm | null = null;

  constructor(show: Show) {
    super(show);
    this.id = show.id;
    this.description = show.description ?? null;
    this.showDate = new Date(show.showDate);
    this.visibleFrom = new Date(show.visibleFrom);
    this.externalTicketLink = show.externalTicketLink;
    this.attendanceValidationPattern = show.attendanceValidationPattern;
    this.tourId = show.tourId;
    this.addressInput = new ShowAddressForm(show.address);
    this.ticketSalesInput = show.ticketSales ? new TicketSalesForm(show.ticketSales) : null;
    this.borderControlInput = show.borderControl ? new BorderControlForm(show.borderControl) : null;
    this.meetAndGreetInput = show.meetAndGreet ? new MeetAndGreetForm(show.meetAndGreet) : null;
    this.chatInput = show.chat ? new ChatForm(show.chat) : null;
  }
}
