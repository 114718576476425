<template>
  <base-stack class="statistics">
    <base-stack v-if="tour?.meetAndGreet" as="dl" class="box">
      <base-stack direction="row" justify="space-between">
        <dt>Secret Gathering Tickets (+ Bundle) sold</dt>
        <dd>{{ tour.meetAndGreet.ticketsSold }}</dd>
      </base-stack>
      <base-stack direction="row" justify="space-between">
        <dt>Secret Gathering Bundles sold</dt>
        <dd>{{ tour.meetAndGreet.bundlesSold }}</dd>
      </base-stack>
    </base-stack>

    <meet-and-greet-purchases-table v-if="tour?.meetAndGreet" :tour="tour" />
  </base-stack>
</template>

<script lang="ts">
import { TourFragments } from '@/apollo/fragments/tour';
import { Tour } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MeetAndGreetPurchasesTable from '../components/MeetAndGreetPurchasesTable.vue';

@Component({
  components: { MeetAndGreetPurchasesTable },
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            ...TourStatistics
          }
        }
        ${TourFragments.TourStatistics}
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class TourStatistics extends Vue {
  @Prop({ required: true })
  public tourId!: string;

  tour?: Tour;
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid $color-theme-red;
  padding: 16px;
}
</style>
