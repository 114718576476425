<template>
  <base-stack class="bundle" v-if="tour?.meetAndGreet">
    <!-- <base-stack direction="row" :spacing="2">
      <base-button @click="shouldAddProduct = true">Add product</base-button>
    </base-stack> -->
    <app-cell
      class="box"
      v-if="form || tour.meetAndGreet.bundle"
      :edit="!!form"
      :loading="!!form && form.isLoading"
      :deletable="false"
      @edit="onEdit"
      @save="onSave"
      @cancel="onCancel"
    >
      <base-stack v-if="tour.meetAndGreet.bundle" slot="content" direction="row" :spacing="4">
        <slider class="slider" :images="tour.meetAndGreet.bundle.imageUrls" />
        <base-stack>
          <h2 class="title">{{ tour.meetAndGreet.bundle.title }}</h2>
          <strong v-if="tour.meetAndGreet.bundle.isDisabled">Purchases are disabled for this bundle.</strong>
          <p class="price">
            Price:
            <b>{{ tour.meetAndGreet.bundle.price }}</b>
            <strong class="usd">({{ priceInUsd }})</strong>
          </p>
          <div v-html="tour.meetAndGreet.bundle.description" />
        </base-stack>
      </base-stack>

      <base-stack slot="form" as="form" v-if="form">
        <base-form-input label="Title">
          <input v-model="form.title" placeholder="Title" />
        </base-form-input>
        <base-form-input label="Description">
          <app-html-editor v-model="form.description" />
        </base-form-input>
        <base-form-input label="Price">
          <input v-model.number="form.price" placeholder="Price" type="number" />
          <template slot="hint">{{
            inputPriceInUsd ? `= ${inputPriceInUsd}` : 'In USD x 1000, i.e. $100.00 = 100000'
          }}</template>
        </base-form-input>
        <base-form-input label="Images">
          <input @change="onFileUpload" placeholder="Image" type="file" accept="image/png, image/jpeg" multiple />
        </base-form-input>
        <div class="box" v-if="tour.meetAndGreet.bundle">
          <base-checkbox v-model="form.disabled" class="form__input">Disable purchases</base-checkbox>
        </div>
      </base-stack>
    </app-cell>
    <base-stack v-else>
      <base-stack direction="row" :spacing="2">
        <base-button @click="onEdit">Add Secret Gathering Bundle</base-button>
      </base-stack>
    </base-stack>
  </base-stack>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import Slider from '@/components/Slider.vue';
import { Tour } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SetMeetAndGreetBundleForm } from '../forms/meetAndGreetBundle/SetMeetAndGreetBundleForm';
import { getFilesFromUploadEvent } from '../utils/getFilesFromUploadEvent';

@Component({
  components: {
    Slider,
    AppCell,
    AppHtmlEditor,
  },
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            id
            meetAndGreet {
              bundle {
                id
                title
                description
                price
                imageUrls
                isDisabled
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class TourBundle extends Vue {
  @Prop({ required: true })
  public tourId!: string;

  public form = null as SetMeetAndGreetBundleForm | null;

  public get priceInUsd(): string | null {
    const price = this.tour?.meetAndGreet?.bundle?.price;
    return price ? `$${price / 1000}` : null;
  }

  public get inputPriceInUsd(): string | null {
    const price = this.form?.price;
    return price ? `$${price / 1000}` : null;
  }

  onEdit() {
    this.form = new SetMeetAndGreetBundleForm({
      tourId: this.tourId,
      bundle: this.tour?.meetAndGreet?.bundle ?? undefined,
    });
  }

  async onSave() {
    await this.form?.save();
    this.form = null;
  }

  onCancel() {
    this.form = null;
  }

  onFileUpload(e: HTMLFileUploadEvent) {
    this.form!.images = getFilesFromUploadEvent(e);
  }

  tour?: Tour;
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid $color-theme-red;
  padding: 16px;
}

.slider {
  max-width: 200px;
}

.title {
  margin-bottom: 0;
}

.price {
  b {
    font-weight: bold;
  }
  strong {
    margin-left: 8px;
  }
}

form {
  max-width: 600px;
}
</style>
