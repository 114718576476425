import { v4 as uuid } from 'uuid';
import { Field } from '../../../form/decorators/Field';
import EmbeddedForm from '../../../form/EmbeddedForm';
import { Variant } from '../../../types/api';

export class MeetAndGreetVariantForm extends EmbeddedForm<Variant> {
  key: string;

  @Field({ static: true })
  public id?: string;

  @Field()
  public name: string = '';

  @Field()
  public sortOrder = 0;

  constructor(variant?: Omit<Variant, 'id'> & { id?: string }) {
    const id = variant?.id ?? uuid();
    super(variant ? { ...variant, id } : undefined);
    this.key = id;
    if (variant) {
      this.id = id;
      this.name = variant.name;
      this.sortOrder = variant.sortOrder;
    }
  }
}
