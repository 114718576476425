import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { MeetAndGreetBundle } from '@/types/api';
import gql from 'graphql-tag';

@Form({
  apollo: {
    mutation: gql`
      mutation SetMeetAndGreetBundle($input: SetMeetAndGreetBundleInput!) {
        setMeetAndGreetBundle(input: $input) {
          bundle {
            id
            title
            description
            price
          }
        }
      }
    `,
    refetchQueries: ['Tour'],
  },
})
export class SetMeetAndGreetBundleForm extends GraphQLForm<MeetAndGreetBundle> {
  @Field({ static: true })
  public tourId: string;

  @Field()
  public title: string = '';

  @Field()
  public description: string = '';

  @Field()
  public price: number = 0;

  @Field()
  public images: Array<File> | null = null;

  @Field()
  public disabled = false;

  constructor(args: { tourId: string; bundle?: Partial<MeetAndGreetBundle> }) {
    super();
    this.tourId = args.tourId;
    if (args.bundle) {
      this.title = args.bundle.title || '';
      this.description = args.bundle.description || '';
      this.price = args.bundle.price || 0;
      this.disabled = args.bundle.isDisabled || false;
    }
  }
}
