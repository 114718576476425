const createLocalStorageStore = (key: string) => ({
  get() {
    return localStorage.getItem(key);
  },
  set(value: string) {
    localStorage.setItem(key, value);
  },
});

export const tourStore = createLocalStorageStore('tour');
