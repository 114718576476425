<template>
  <base-stack class="settings">
    <app-cell :edit="!!meetAndGreetForm" :loading="meetAndGreetForm?.isLoading">
      <template slot="content">
        <p v-if="!tour?.meetAndGreet">Secret Gathering has not been configured for this tour.</p>
        <base-stack v-else direction="row" :spacing="6">
          <slider class="slider" :images="tour.meetAndGreet.ticketImageUrls" />
          <base-stack>
            <h2 class="title">{{ tour.meetAndGreet.ticketTitle }}</h2>
            <p class="summary" v-if="tour.meetAndGreet.ticketSummary">{{ tour.meetAndGreet.ticketSummary }}</p>
            <span v-if="tour.meetAndGreet.ticketDescription" v-html="tour.meetAndGreet.ticketDescription" />
            <base-stack class="box" as="fieldset" :spacing="2">
              <legend>Default settings</legend>
              <p class="entry">
                <span class="entry__label">Ticket price</span>
                <span class="entry__value">
                  {{ tour.meetAndGreet.defaultTicketPrice || 'Not set' }}
                  <strong v-if="tour.meetAndGreet.defaultTicketPrice">
                    ({{ toUsd(tour.meetAndGreet.defaultTicketPrice) }})
                  </strong>
                </span>
              </p>
              <p class="entry">
                <span class="entry__label">Purchase quota</span>
                <span class="entry__value">{{ tour.meetAndGreet.defaultPurchaseQuota || 'Not set' }}</span>
              </p>
              <p class="entry">
                <span class="entry__label">Purchase quota notification threshold</span>
                <span class="entry__value">{{
                  tour.meetAndGreet.defaultPurchaseQuotaNotificationThreshold || 'Not set'
                }}</span>
              </p>
              <p class="entry">
                <span class="entry__label">Release date</span>
                <span class="entry__value">
                  <template v-if="tour.meetAndGreet.defaultReleaseDate">{{
                    tour.meetAndGreet.defaultReleaseDate | dayjs.utc('MMM DD, YYYY (HH:mm)')
                  }}</template>
                  <template v-else>Not set</template>
                </span>
              </p>
              <p class="entry">
                <span class="entry__label">Instructions</span>
                <span class="entry__value">{{ tour.meetAndGreet.defaultInstructions || 'Not set' }}</span>
              </p>
            </base-stack>
          </base-stack>
        </base-stack>
      </template>
      <template slot="form" v-if="meetAndGreetForm">
        <base-stack as="form" id="meet-and-greet-form" @submit.prevent="enableMeetAndGreet">
          <base-form-input label="Ticket title">
            <input v-model="meetAndGreetForm.ticketTitle" />
            <template slot="hint">Shown when purchasing tickets</template>
          </base-form-input>
          <base-form-input label="Ticket description">
            <app-html-editor v-model="meetAndGreetForm.ticketDescription" />
            <template slot="hint">Shown when purchasing tickets</template>
          </base-form-input>
          <base-form-input label="Ticket summary">
            <input v-model="meetAndGreetForm.ticketSummary" />
            <template slot="hint">Shown in confirmation email, e.g. "Ticket incl. poster, patch, lanyard"</template>
          </base-form-input>
          <base-form-input label="Ticket images">
            <input @change="onFileUpload" placeholder="Image" type="file" accept="image/png, image/jpeg" multiple />
          </base-form-input>
          <base-stack as="fieldset" class="box">
            <legend>Default settings</legend>
            <base-form-input label="Ticket price" :optional="true">
              <input type="number" v-model.number="meetAndGreetForm.defaultTicketPrice" />
              <template slot="hint">{{
                meetAndGreetForm.defaultTicketPrice
                  ? `= ${toUsd(meetAndGreetForm.defaultTicketPrice)}`
                  : 'In USD x 1000, i.e. $100.00 = 100000'
              }}</template>
            </base-form-input>
            <base-form-input label="Loyal quota" :optional="true">
              <input type="number" v-model.number="meetAndGreetForm.defaultLoyalQuota" />
              <template slot="hint">Top X citizens by score</template>
            </base-form-input>
            <base-form-input label="Random quota" :optional="true">
              <input type="number" v-model.number="meetAndGreetForm.defaultRandomQuota" />
              <template slot="hint">Random X Citizens</template>
            </base-form-input>
            <base-form-input label="Purchase quota" :optional="true">
              <input type="number" v-model.number="meetAndGreetForm.defaultPurchaseQuota" />
              <template slot="hint">Ticket quota for purchase</template>
            </base-form-input>
            <base-form-input label="Purchase quota notification threshold" :optional="true">
              <input type="number" v-model.number="meetAndGreetForm.defaultPurchaseQuotaNotificationThreshold" />
              <template slot="hint">Display a notification when X tickets left</template>
            </base-form-input>
            <base-form-input label="Release date (UTC)" :optional="true">
              <input type="datetime-local" v-model="meetAndGreetForm.defaultReleaseDate" />
              <span slot="hint">Loyal citizens will be selected and tickets will go for purchase</span>
            </base-form-input>
            <base-form-input class="form__input" label="Instructions" :optional="true">
              <input
                v-model="meetAndGreetForm.defaultInstructions"
                placeholder="Instructions for Secret Gathering ticket holders"
              />
              <span slot="hint">Included in email and shown on holders show page</span>
            </base-form-input>
          </base-stack>
        </base-stack>
      </template>
      <template slot="actions">
        <template v-if="!meetAndGreetForm">
          <base-button key="edit" @click="onConfigureMeetAndGreet">Configure</base-button>
        </template>
        <template v-else>
          <base-button key="submit" type="submit" form="meet-and-greet-form">Submit</base-button>
          <base-button key="cancel" @click="meetAndGreetForm = null">Cancel</base-button>
        </template>
      </template>
    </app-cell>

    <base-stack class="box">
      <base-stack as="form" direction="row" align="end" v-if="tour" @submit.prevent="onUpdateTourName">
        <base-form-input label="Tour Name">
          <input v-model="tourName" placeholder="Enter tour name" :disabled="updatingTour" />
        </base-form-input>
        <base-button type="submit" :disabled="!canUpdateTourName || updatingTour" :loading="updatingTour">
          Rename
        </base-button>
      </base-stack>
    </base-stack>

    <base-stack class="box" align="start">
      <base-button :danger="true" @click="onDeleteTour" :disabled="!tour || deletingTour">
        {{ deletingTour ? 'Deleting...' : 'Delete Tour' }}
      </base-button>
    </base-stack>
  </base-stack>
</template>

<script lang="ts">
import { TourFragments } from '@/apollo/fragments/tour';
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import Slider from '@/components/Slider.vue';
import { Tour } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TourMeetAndGreetForm } from '../forms/tour/TourMeetAndGreetForm';
import { getFilesFromUploadEvent } from '../utils/getFilesFromUploadEvent';

@Component({
  components: {
    AppCell,
    Slider,
    AppHtmlEditor,
  },
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            ...TourSettings
          }
        }
        ${TourFragments.TourSettings}
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class TourSettings extends Vue {
  @Prop({ required: true })
  public tourId!: string;

  tour?: Tour;
  deletingTour = false;
  updatingTour = false;
  tourName = '';
  meetAndGreetForm = null as TourMeetAndGreetForm | null;

  @Watch('tour', { immediate: true })
  onTourChanged() {
    if (this.tour) {
      this.tourName = this.tour.name;
    }
  }

  get canUpdateTourName(): boolean {
    return !!this.tour && this.tourName.trim() !== '' && this.tourName !== this.tour.name;
  }

  public onConfigureMeetAndGreet() {
    if (!this.tour) return;
    this.meetAndGreetForm = new TourMeetAndGreetForm(this.tour);
  }

  onFileUpload(e: HTMLFileUploadEvent) {
    this.meetAndGreetForm!.images = getFilesFromUploadEvent(e);
  }

  public async onUpdateTourName() {
    if (!this.canUpdateTourName) return;

    this.updatingTour = true;
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateTour($input: UpdateTourInput!) {
            updateTour(input: $input) {
              tour {
                id
                name
              }
            }
          }
        `,
        variables: {
          input: {
            id: this.tourId,
            name: this.tourName.trim(),
          },
        },
        refetchQueries: ['Tour', 'Tours'],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      this.$modal.alert({
        title: 'Error',
        message: `Failed to update tour name: ${error instanceof Error ? error.message : 'Unknown error'}`,
      });
    } finally {
      this.updatingTour = false;
    }
  }

  public toUsd(value?: number) {
    return value != null ? `$${value / 1000}` : null;
  }

  public async enableMeetAndGreet() {
    await this.meetAndGreetForm?.save();
    this.meetAndGreetForm = null;
  }

  public async onDeleteTour() {
    if (!this.tour) return;

    if (this.tour.shows.length) {
      this.$modal.alert({
        title: 'Error',
        message: 'This tour has shows. Remove them first.',
      });
      return;
    }

    const confirmed = await this.$modal.confirm({
      title: 'Delete Tour',
      message: `Are you sure you want to delete the tour "${this.tour.name}" and all associated shows? This action cannot be undone.`,
    });

    if (!confirmed) return;

    this.deletingTour = true;
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteTour($input: DeleteTourInput!) {
            deleteTour(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: { id: this.tourId },
        },
        refetchQueries: ['Tours'],
        awaitRefetchQueries: true,
      });

      if (data?.deleteTour.success) {
        this.$modal.alert({
          title: 'Tour Deleted',
          message: 'The tour has been successfully deleted.',
        });
        this.$router.push('/tours').catch(() => {});
      } else {
        throw new Error('Failed to delete tour');
      }
    } catch (error) {
      this.$modal.alert({
        title: 'Error',
        message: `Failed to delete tour: ${error instanceof Error ? error.message : 'Unknown error'}`,
      });
    } finally {
      this.deletingTour = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid $color-theme-red;
  padding: 16px;

  &-title {
    margin-top: 0;
    color: $color-theme-red;
  }
}

.slider {
  width: 240px;
}

.title {
  margin-bottom: 0;
}

.summary {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: $color-theme-red;
  font-style: italic;
}

.entry {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 8px;

  &__value {
    float: right;
    font-weight: bold;
    strong {
      margin-left: 2px;
      font-size: 1rem;
    }
    &.danger {
      color: $color-theme-red;
    }
    &.success {
      color: $color-success;
    }
  }
}

form {
  max-width: 600px;

  label {
    flex: 1;
  }
}
</style>
