<template>
  <base-stack class="shows">
    <base-stack direction="row">
      <base-button class="shows__add" @click="onAddShow">Add show</base-button>
      <base-button class="shows__add" @click="onImport" :disabled="loadingImport">Import from bandsintown</base-button>
      <base-button class="shows__add" @click="onTogglePastShows">{{
        showPastShows ? 'Hide past shows' : 'Show past shows'
      }}</base-button>
    </base-stack>

    <transition-group name="list" mode="out-in" class="shows__list" tag="ul">
      <app-show-cell
        v-for="form in forms"
        :key="form.id"
        :data="form.data"
        :tour="tour"
        class="list__show"
        @close="onCloseForm(form)"
      />
      <template v-if="showPastShows">
        <app-show-cell
          v-for="show in pastShows"
          :key="show.id"
          :show="show"
          :tour="tour"
          class="list__show"
          :focused="focusShow?.id === show.id"
        />
      </template>
      <app-show-cell
        v-for="show in futureShows"
        :key="show.id"
        :show="show"
        :tour="tour"
        class="list__show"
        :focused="focusShow?.id === show.id"
      />
    </transition-group>
  </base-stack>
</template>

<script lang="ts">
import { TourFragments } from '@/apollo/fragments/tour';
import AppCell from '@/components/Cell.vue';
import AppShowCell from '@/components/ShowCell.vue';
import { ImportedShow, Mutation, MutationImportShowsArgs, Tour } from '@/types/api';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

type FormContext = { id: string; data?: Omit<ImportedShow, 'bitId'> };

@Component({
  components: { AppShowCell, AppCell },
  apollo: {
    tour: {
      query: gql`
        query Tour($id: ID!) {
          tour(id: $id) {
            ...TourShows
          }
        }
        ${TourFragments.TourShows}
      `,
      variables() {
        return {
          id: this.tourId,
        };
      },
      fetchPolicy: 'cache-and-network',
    },
    tours: {
      query: gql`
        query Tours {
          tours {
            ...TourList
          }
        }
        ${TourFragments.TourList}
      `,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class TourShows extends Vue {
  @Prop({ required: true })
  public tourId!: string;

  tour?: Tour;
  tours: Tour[] = [];
  forms: FormContext[] = [];
  loadingImport = false;
  showPastShows = false;

  public get shows() {
    return this.tour?.shows ?? [];
  }

  public get focusShow() {
    return this.shows.find((show) => show.id === this.$route.hash.slice(1));
  }

  public get pastShows() {
    return this.shows.filter((show) => dayjs().isAfter(show.showDate));
  }

  public get futureShows() {
    return this.shows.filter((show) => !dayjs().isAfter(show.showDate));
  }

  public onAddShow() {
    this.forms.push({ id: Date.now().toString() });
  }

  public onTogglePastShows() {
    this.showPastShows = !this.showPastShows;
  }

  public async onImport() {
    this.loadingImport = true;
    try {
      const { data } = await this.$apollo.mutate<{
        importShows: Mutation['importShows'];
      }>({
        mutation: gql`
          mutation ImportShows($input: ImportShowsInput!) {
            importShows(input: $input) {
              shows {
                bitId
                description
                externalTicketLink
                showDate
                address {
                  venue
                  city
                  countryId
                }
              }
            }
          }
        `,
        variables: {
          input: {
            tourId: this.tourId,
          },
        } satisfies MutationImportShowsArgs,
      });
      const shows = data?.importShows.shows;
      if (!shows) {
        throw new Error('No shows imported');
      }
      if (shows.length === 0) {
        this.$modal.alert({
          title: 'No shows imported',
          message: 'No shows were imported from bandsintown',
        });
        return;
      }
      this.$modal.alert({
        title: 'Shows imported',
        message: `${shows.length} shows were imported from bandsintown`,
      });
      this.forms.push(
        ...shows.map(({ bitId, ...formData }) => ({
          id: bitId,
          data: { ...formData, tourId: this.tourId },
        }))
      );
    } finally {
      this.loadingImport = false;
    }
  }

  public onCloseForm(form: FormContext) {
    this.forms = this.forms.filter((a) => a.id !== form.id);
  }

  @Watch('focusShow', { immediate: true })
  public onShowFocusChange() {
    const focusShow = this.focusShow;
    if (focusShow && this.pastShows.some((past) => past.id === focusShow.id)) {
      this.showPastShows = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid $color-theme-red;
  padding: 16px;
}

.list__show {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
