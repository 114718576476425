<template>
  <app-cell class="stats" :editable="false" :deletable="false">
    <base-stack>
      <base-stack direction="row" justify="space-between">
        <h3>Secret Gathering Stats</h3>
        <tour-select v-model="tourId" />
      </base-stack>

      <base-stack as="dl">
        <base-stack direction="row" justify="space-between">
          <dt>Tickets sold</dt>
          <dd>{{ tour?.meetAndGreet?.ticketsSold }}</dd>
        </base-stack>
        <base-stack direction="row" justify="space-between">
          <dt>Bundles sold</dt>
          <dd>{{ tour?.meetAndGreet?.bundlesSold }}</dd>
        </base-stack>
      </base-stack>

      <meet-and-greet-purchases-table v-if="tour" :tour="tour" />
    </base-stack>
  </app-cell>
</template>

<script lang="ts">
import { TourFragments } from '@/apollo/fragments/tour';
import AppCell from '@/components/Cell.vue';
import MeetAndGreetPurchasesTable from '@/components/MeetAndGreetPurchasesTable.vue';
import TourSelect from '@/components/TourSelect.vue';
import { Tour } from '@/types/api';
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { AppCell, MeetAndGreetPurchasesTable, TourSelect },
  apollo: {
    tour: {
      query: gql`
        query Tour($tourId: ID!) {
          tour(id: $tourId) {
            ...TourStatistics
          }
        }
        ${TourFragments.TourStatistics}
      `,
      variables() {
        return {
          tourId: this.tourId,
        };
      },
      skip() {
        return !this.tourId;
      },
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class MeetAndGreetStats extends Vue {
  tour?: Tour;

  tourId = null as string | null;
}
</script>

<style lang="scss" scoped>
.stats {
  table {
    margin-top: 16px;
    width: 100%;

    thead tr,
    tbody tr:not(:last-child) {
      border-bottom: 1px solid $color-theme-yellow;
    }

    thead {
      font-weight: bold;

      tr {
        th {
          padding: 8px;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;
          padding: 0 8px;

          &:has(img) {
            padding: 0;
            width: 40px;
          }

          &:last-child {
            text-align: right;
          }
        }

        img {
          margin-top: 4px;
          height: 32px;
          width: 32px;
          object-fit: fit;
        }

        .purchases {
          color: $color-theme-red;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
