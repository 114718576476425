import { ShowFragments } from '@/apollo/fragments/show';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { ImportedShow, Show } from '@/types/api';
import gql from 'graphql-tag';
import { ShowForm } from '.';
import { ChatForm } from '../chat/ChatForm';
import { BorderControlForm } from './BorderControlForm';
import { MeetAndGreetForm } from './MeetAndGreetForm';
import { ShowAddressForm } from './ShowAddressForm';
import { TicketSalesForm } from './TicketSalesForm';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateShow($input: CreateShowInput!) {
        createShow(input: $input) {
          show {
            ...Show
          }
        }
      }
      ${ShowFragments.Show}
    `,
    refetchQueries: ['Tour'],
  },
})
export class CreateShowForm extends GraphQLForm<Show> implements ShowForm {
  public static fromImport(data: ImportedShow & { tourId: string }): CreateShowForm {
    const form = new CreateShowForm(data.tourId);
    form.description = data.description || '';
    form.externalTicketLink = data.externalTicketLink;
    form.showDate = new Date(data.showDate);
    form.addressInput.venue = data.address.venue;
    form.addressInput.city = data.address.city || '';
    form.addressInput.countryId = data.address.countryId || 'US';
    form.addressInput.administrativeArea = data.address.administrativeArea || undefined;
    return form;
  }

  @Field({ transform: (value) => value || null })
  public description: string = '';

  @Field()
  public showDate: Date = new Date();

  @Field()
  public tourId: string;

  @Field()
  public visibleFrom?: Date;

  @Field()
  public externalTicketLink?: string | null;

  @Field()
  public attendanceValidationPattern?: string | null;

  @Field()
  public addressInput = new ShowAddressForm();

  @Field()
  public meetAndGreetInput: MeetAndGreetForm | null = null;

  @Field()
  public ticketSalesInput: TicketSalesForm | null = null;

  @Field()
  public borderControlInput: BorderControlForm | null = null;

  @Field()
  public chatInput: ChatForm | null = null;

  constructor(tourId: string) {
    super();
    this.tourId = tourId;
  }
}
