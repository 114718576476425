import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { UserRemark } from '@/types/api';
import gql from 'graphql-tag';
import { UserRemarkForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation CreateUserRemark($input: CreateUserRemarkInput!) {
        createUserRemark(input: $input) {
          remark {
            id
            comment
            createdAt
            author {
              id
              username
            }
          }
        }
      }
    `,
    refetchQueries: ['User'],
  },
})
export class CreateUserRemarkForm extends GraphQLForm<UserRemark> implements UserRemarkForm {
  @Field()
  public comment = '';

  @Field()
  public targetUserId = '';

  public save() {
    return super.save({
      optimisticResponse: {
        createUserRemark: {
          remark: {
            id: 'optimistic-id',
            comment: this.comment,
            createdAt: new Date().toISOString(),
            author: {
              id: 'current-user-id',
              username: 'Current User',
              __typename: 'User',
            },
            __typename: 'UserRemark',
          },
          __typename: 'CreateUserRemarkPayload',
        },
        __typename: 'Mutation',
      },
    });
  }
}
