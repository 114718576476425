import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { UserRemark } from '@/types/api';
import gql from 'graphql-tag';
import { UserRemarkForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdateUserRemark($input: UpdateUserRemarkInput!) {
        updateUserRemark(input: $input) {
          remark {
            id
            comment
            createdAt
            author {
              id
              username
            }
          }
        }
      }
    `,
    refetchQueries: ['User'],
  },
})
export class UpdateUserRemarkForm extends GraphQLForm<UserRemark> implements UserRemarkForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public comment: string;

  constructor(userRemark: UserRemark) {
    super(userRemark);
    this.id = userRemark.id;
    this.comment = userRemark.comment;
  }

  public save() {
    return super.save({
      optimisticResponse: {
        updateUserRemark: {
          remark: {
            id: this.id,
            comment: this.comment,
            createdAt: new Date().toISOString(),
            author: {
              id: 'current-user-id',
              username: 'Current User',
              __typename: 'User',
            },
            __typename: 'UserRemark',
          },
          __typename: 'UpdateUserRemarkPayload',
        },
        __typename: 'Mutation',
      },
    });
  }
}
