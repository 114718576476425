import { PurchasableFragments } from '@/apollo/fragments/purchasable';
import { Field } from '@/form/decorators/Field';
import { Form } from '@/form/decorators/Form';
import GraphQLForm from '@/form/GraphQLForm';
import { MeetAndGreetVariantForm } from '@/forms/purchasable/variant/MeetAndGreetVariantForm';
import { MeetAndGreetProduct } from '@/types/api';
import gql from 'graphql-tag';
import { MeetAndGreetProductForm } from '.';

@Form({
  apollo: {
    mutation: gql`
      mutation UpdatePurchasable($input: UpdatePurchasableInput!) {
        updatePurchasable(input: $input) {
          purchasable {
            ...Purchasable
          }
        }
      }
      ${PurchasableFragments.Purchasable}
    `,
  },
})
export class UpdateMeetAndGreetProductForm extends GraphQLForm<MeetAndGreetProduct> implements MeetAndGreetProductForm {
  @Field({
    static: true,
  })
  public readonly id: string;

  @Field()
  public title: string;

  @Field()
  public description: string;

  @Field()
  public price: number;

  @Field()
  public sortOrder?: number;

  @Field()
  public images: Array<File> | null = null;

  @Field()
  public variantInputs = [new MeetAndGreetVariantForm()];

  // Always send both fields
  @Field({ static: true })
  public loyalExclusive: boolean;

  // Always send both fields
  @Field({ static: true })
  public randomExclusive: boolean;

  constructor(product: MeetAndGreetProduct) {
    super(product);
    this.id = product.id;
    this.title = product.title;
    this.description = product.description;
    this.price = product.price;
    this.sortOrder = product.sortOrder;
    this.variantInputs = product.variants.map((variant) => new MeetAndGreetVariantForm(variant));
    this.loyalExclusive = product.loyalExclusive;
    this.randomExclusive = product.randomExclusive;
  }
}
