<template>
  <app-cell
    class="citizenship-cell"
    :edit="!!form"
    :deletable="false"
    :loading="!!form && form.isLoading"
    @edit="onEdit"
    @save="onSave"
    @cancel="onCancel"
  >
    <template slot="content" v-if="citizenship">
      <div class="citizenship-cell__content">
        <h2 class="citizenship-cell__title">
          {{ citizenship.title }}
        </h2>
        <p class="citizenship-cell__price usd">
          Monthly price:
          <strong>{{ toUsdString(citizenship.monthlyPrice) }}</strong>
        </p>
        <p class="citizenship-cell__price">
          Yearly price:
          <strong>{{ toUsdString(citizenship.yearlyPrice) }}</strong>
        </p>
        <p class="citizenship-cell__description">
          <img
            class="citizenship-cell__image"
            v-if="citizenship.imageUrl"
            :src="citizenship.imageUrl"
            :alt="citizenship.title"
          />
          <span v-html="citizenship.description" />
        </p>
      </div>
    </template>
    <template slot="form" v-if="!!form">
      <form @submit.prevent class="citizenship-cell__form">
        <base-form-input class="form__input" label="Title">
          <input v-model="form.title" placeholder="Title" />
        </base-form-input>
        <base-form-input label="Product image" class="form__input">
          <input @change="onFileUpload" placeholder="Image" type="file" accept="image/png, image/jpeg" />
        </base-form-input>
        <base-form-input class="form__input" label="Description">
          <app-html-editor v-model="form.description" placeholder="Body" />
        </base-form-input>
        <base-form-input class="form__input" label="Monthly price (USD cents)">
          <input v-model.number="form.monthlyPrice" placeholder="Price" type="number" />
        </base-form-input>
        <base-form-input class="form__input" label="Yearly price (USD cents)">
          <input v-model.number="form.yearlyPrice" placeholder="Price" type="number" />
        </base-form-input>
      </form>
    </template>
  </app-cell>
</template>

<script lang="ts">
import AppCell from '@/components/Cell.vue';
import AppHtmlEditor from '@/components/HtmlEditor.vue';
import { UpdateCitizenshipForm } from '@/forms/citizenship/UpdateCitizenshipForm';
import { getFilesFromUploadEvent } from '@/utils/getFilesFromUploadEvent';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Citizenship } from '../types/api';

@Component({
  components: { AppCell, AppHtmlEditor },
})
export default class CitizenshipCell extends Vue {
  @Prop({ required: true })
  citizenship!: Citizenship;

  public form: UpdateCitizenshipForm | null = null;

  public toUsdString(cents: number) {
    return `$${(cents / 100).toFixed(2)}`;
  }

  public onEdit() {
    this.form = new UpdateCitizenshipForm(this.citizenship);
  }

  public onCancel() {
    this.closeForm();
  }

  public async onSave() {
    await this.form?.save();
    this.closeForm();
  }

  public onFileUpload(e: HTMLFileUploadEvent) {
    const files = getFilesFromUploadEvent(e);
    this.form!.image = files[0];
  }

  private closeForm() {
    this.form = null;
    this.$emit('close');
  }
}
</script>

<style lang="scss" scoped>
.citizenship-cell {
  &__title {
    strong {
      margin-left: 8px;
      font-size: 1rem;
    }
  }
  &__price {
    margin-bottom: 16px;
  }
  &__image {
    height: 220px;
    width: 240px;
    max-width: 400px;
    object-fit: contain;
    margin-right: 16px;
    float: left;
  }
  &__description {
  }
  &__form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    .form__input {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
