import { apolloClient } from '@/apollo/client';
import gql from 'graphql-tag';
import Vue from 'vue';
import { NavigationGuard } from 'vue-router';
import { tourStore } from '../store/tour';
import { Query, Tour, UserRole } from '../types/api';

const checkIfUserIsAuthorized = async (): Promise<boolean> => {
  const { data } = await apolloClient.query<{ viewer: Query['viewer'] }>({
    query: gql`
      {
        viewer {
          id
          role
        }
      }
    `,
    fetchPolicy: 'no-cache',
  });
  return (data && data.viewer && data.viewer.role === UserRole.Admin) || false;
};

export const adminGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const isAuthorized = await checkIfUserIsAuthorized();
  if (isAuthorized) {
    next();
  } else {
    next('/login');
  }
};

export const loginGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const isAuthorized = await checkIfUserIsAuthorized();
  if (isAuthorized) {
    next('/');
  } else {
    next();
  }
};

const findTours = async (): Promise<Tour[]> => {
  const { data } = await apolloClient.query<{ tours: Query['tours'] }>({
    query: gql`
      {
        tours {
          id
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
  return data.tours;
};

export const toursGuard: NavigationGuard<Vue> = async (to, from, next) => {
  if (to.params.tourId) {
    next({ name: 'tour', params: { tourId: to.params.tourId } });
    return;
  }
  const tours = await findTours();
  const tourId = tourStore.get();
  if (tourId && tours.some((t) => t.id === tourId)) {
    next({ name: 'tours', params: { tourId } });
  } else {
    const firstTour = tours[0];
    tourStore.set(firstTour.id);
    next({ name: 'tours', params: { tourId: firstTour.id } });
  }
};
