import gql from 'graphql-tag';
import { ChatFragments } from './chat';

export namespace ShowFragments {
  export const Show = gql`
    fragment Show on Show {
      id
      description
      showDate
      visibleFrom
      externalTicketLink
      attendanceValidationPattern
      attendanceCount
      isPast
      tourId
      borderControl {
        earlyEntryLimit
        earlyEntryCount
        details
      }
      ticketSales {
        limit
        price
        availableFrom
        availableUntil
        note
        ticketsCount
        hasOpened
        hasClosed
        isAvailable
      }
      meetAndGreet {
        price
        loyalQuota
        randomQuota
        purchaseQuota
        purchaseQuotaNotificationThreshold
        releaseDate
        instructions
        isReleased
        isApplicationsEnabled
        isRemindersEnabled
        isSoldOut
        totalQuota
        applicationsCount
        numberOfValidTickets
        numberOfTicketsPurchased
        numberOfLoyalTickets
        numberOfRandomTickets
      }
      address {
        venue
        city
        country {
          id
          name
        }
      }
      chat {
        ...Chat
      }
    }
    ${ChatFragments.Chat}
  `;
}
